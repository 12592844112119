var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-0 container-fluid lecturer-live-lectures h-100",staticStyle:{"text-align":"left"}},[_c('article',[_c('section',{staticStyle:{"padding-top":"50px"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"mb-4 row w-100"},[_c('div',{staticClass:"px-0 pl-3 mb-0 text-left col-12"},[_c('BreadCrumbComponent',{attrs:{"pageTitles":['Live Lectures']}})],1)]),_c('div',{staticClass:"row w-100"},[_c('div',{staticClass:"text-left col-12"},[_c('div',{staticStyle:{"font-size":"20px","font-weight":"400","color":"var(--el-app-primary)","margin-bottom":"15px"}},[_c('strong',[_vm._v("Live Lectures for Lecturer "),(_vm.lecturer)?_c('div',[_vm._v(" ("+_vm._s(`${_vm.lecturer.first_name} ${_vm.lecturer.last_name}`)+") ")]):_vm._e()])])])]),_c('div',{staticStyle:{"margin-bottom":"20px","display":"flex","align-items":"center","justify-content":"space-between"}},[_c('div',{staticClass:"row w-100 d-flex"},[_c('div',{staticClass:"col-4"},[_c('el-select',{staticClass:"d-block",attrs:{"placeholder":"Select"},on:{"handleOptionClick":_vm.handleOptionClick},model:{value:(_vm.selectedFilter),callback:function ($$v) {_vm.selectedFilter=$$v},expression:"selectedFilter"}},_vm._l((_vm.filterOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"col-md-3"},[_c('GenerateLectureReport',{attrs:{"lecturerId":_vm.$route.params.id,"lecturerName":_vm.lecturer
                    ? `${_vm.lecturer.first_name} ${_vm.lecturer.last_name}`
                    : null}})],1)])]),_c('hr'),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(
            _vm.fetchingLiveClassId || (_vm.loading && _vm.live_lectures.length > 0)
          ),expression:"\n            fetchingLiveClassId || (loading && live_lectures.length > 0)\n          "}],staticStyle:{"width":"100%"}},[_c('data-tables-server',{ref:"lecturesTable",attrs:{"data":_vm.live_lectures,"table-props":_vm.tableProps,"pagination-props":{ pageSizes: [10, 25, 50] },"type":"expand","total":_vm.totalLectures,"current-page":_vm.currentPage,"page-size":_vm.pageSize},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"query-change":_vm.loadData}},[_c('div',{attrs:{"slot":"empty"},slot:"empty"},[(_vm.loading && !_vm.loadingError)?_c('ScaleOut',{staticClass:"mt-3",attrs:{"background":'#164B70'}}):(!_vm.loading && _vm.loadingError)?_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.getLiveLectures}},[_vm._v(" Unable to Load Live lectures Now. Please click here to retry ")]):_vm._e(),(!_vm.loading && !_vm.loadingError)?_c('div',[_vm._v("No Live lectures")]):_vm._e()],1),_c('el-table-column',{attrs:{"label":"#","show-overflow-tooltip":true,"label-class-name":"text-center","class-name":"text-center","width":"60px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s((_vm.currentPage - 1) * _vm.pageSize + scope.$index + 1))])]}}])}),_c('el-table-column',{attrs:{"label":"Module(s)","show-overflow-tooltip":true,"label-class-name":"text-left","width":"auto","prop":"course_unit_code"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.course_units_with_batch.length < 6)?_c('div',_vm._l((scope.row
                      .course_units_with_batch),function(moduleItem,index){return _c('div',{key:index},[_c('span',[_vm._v(_vm._s(moduleItem))])])}),0):_c('div',[_vm._v(" "+_vm._s(scope.row.course_units_with_batch.length)+" Intakes ")])]}}])}),_c('el-table-column',{attrs:{"label":"Date","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm.formattedDate(scope.row.date)))])]}}])}),_c('el-table-column',{attrs:{"label":"Start time","width":"90px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.start_time))])]}}])}),_c('el-table-column',{attrs:{"label":"End time","width":"90px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.end_time))])]}}])}),_c('el-table-column',{attrs:{"label":"Status","width":"80px","class-name":"text-center","label-class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                    !scope.row.class_status &&
                    scope.row.has_lecturer_ended_class
                  )?_c('el-tooltip',{attrs:{"content":"Lecture was attended","placement":"top"}},[_c('i',{staticClass:"el-icon-check color-green",staticStyle:{"font-size":"20px","color":"#ffbb33","cursor":"pointer"}})]):(scope.row.ongoing_status === 'TODAY_AND_LIVE')?_c('el-tooltip',{attrs:{"content":"Lecture is Live","placement":"top"}},[_c('i',{staticClass:"fas fa-play color-red",staticStyle:{"font-size":"20px"}})]):(
                    scope.row.date_relative == 1 ||
                    scope.row.ongoing_status === 'TODAY_AND_UPCOMING'
                  )?_c('el-tooltip',{attrs:{"content":"Upcoming","placement":"top"}},[_c('i',{staticClass:"el-icon-alarm-clock",staticStyle:{"font-size":"20px"}})]):(
                    scope.row.date_relative == -1 ||
                    scope.row.ongoing_status === 'TODAY_AND_ENDED' ||
                    scope.row.class_status
                  )?_c('el-tooltip',{attrs:{"content":_vm.toolTipContent(scope.row),"placement":"top"}},[_c('i',{class:{
                      'el-icon-warning-outline': !scope.row.class_status,
                      'el-icon-loading': scope.row.class_status == 'Loading',
                      'el-icon-close color-red':
                        scope.row.class_status == 'Missed',
                      'el-icon-check color-green':
                        scope.row.class_status == 'Attended',
                    },staticStyle:{"font-size":"20px","color":"#ffbb33","cursor":"pointer"},on:{"click":function($event){return _vm.loadAttendanceInfo(scope.row.live_lecture_id)}}})]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Next Schedule","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.next_schedule)?_c('span',[_vm._v(" "+_vm._s(scope.row.next_schedule)+" ")]):_c('i',{staticStyle:{"color":"rgba(0, 0, 0, 0.5)"}},[_vm._v("None")])]}}])}),_c('el-table-column',{attrs:{"label":"Actions","width":"120px","class-name":"text-center","label-class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_c('el-tooltip',{attrs:{"content":"Details","placement":"top"}},[_c('el-button',{attrs:{"type":"primary","icon":"fas fa-eye","circle":""},on:{"click":function($event){return _vm.getLectureDetails(scope.row.live_lecture_id)}}})],1)],1)]}}])})],1)],1)])])]),_c('LectureDetailsDialog',{attrs:{"lectureDetailsLoading":_vm.lectureDetailsLoading,"isVisible":_vm.isLectureDetailsModelVisible,"beforeClose":_vm.beforeDialogClose,"liveLectureDetails":_vm.liveLectureDetails},on:{"delete-live-lecture":_vm.onDeleteLiveLecture}}),_c('ChooseLectureRangeDialog',{attrs:{"dialogVisible":_vm.chooseLectureRangeDialogVisible},on:{"on-close":function($event){_vm.chooseLectureRangeDialogVisible = false},"on-range-chosen":_vm.onRangeChosen}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }