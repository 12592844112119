import { CourseIntakeBatch } from "@/interfaces/courseIntakeBatch.interface";
import getMonthName from "./getMonthName";

export const getFormattedIntake = (intake: CourseIntakeBatch): string => {
  if (!intake) return "";
  return `${intake.course?.course_code || ""} 
  ${getMonthName(intake.month).toUpperCase()} 
  ${intake.year} 
   ${intake.cohort}`;
};
