<template>
  <div class="main-container"
    id="main-chat-container">
    <Chat :messages="[...allChatMessages].reverse()"
      :areChatsAvailable="areChatsAvailable"
      :isLoading="isLoading"
      :failedMessages="failedMessages"
      :failedToDeleteMessage="failedToDeleteMessage"
      :isDeleting="isDeleting"
      :newMessage="newMessage"
      :selectedMessageId="selectedMessageId"
      :chats="chatConversationsSortedByDate"
      :isLoadingSideBarChats="isLoadingSideBarChats"
      :showMainChatWindow="showMainChatWindow"
      @add-message="addUserChatMessage"
      @update-chat-message="updateUserChat"
      @gotMoreMessages="updateUserMessages"
      @remove-failed-message="removeFailedMessage"
      @remove-deleted-message="removeDeletedMessage"
      @update-deleted-message="updateDeletedMessage"
      @delete-start="deleteStart"
      @chat-selected="getSelectedChat" />

    <chatSideBar :chats="chatConversationsSortedByDate"
      :showMainChatWindow="showMainChatWindow"
      class="remove-on-mobile"
      :isLoadingSideBarChats="isLoadingSideBarChats"
      :selectedMessageId="selectedMessageId"
      @chat-selected="getSelectedChat"
      @show-main-window="displaySelectedChat" 
      @fetch-more-conversations="fetchConversations"
      />

  </div>
</template>


<script >
import Chat from "../components/chat/chat-component.vue";
import chatSideBar from "../components/chat/chat-side-bar-component.vue";
import makeApiRequest from "../components/helpers/request-handlers/requestsHandler";
import serverEventHandler from "../components/helpers/request-handlers/serverEventHandler";
import { checkIfItemIsInArray } from "../components/helpers/searchFunctions";
import { sortArrayByDate } from "../components/helpers/sortFunctions";

export default {
  name: "admin-chat",
  components: {
    Chat,
    chatSideBar,
  },
  data() {
    return {
      textInput: "",
      selectedMessageId: "",
      chatsOffset: -50,
      isLoading: true,
      failedToDeleteMessage: false,
      showMainChatWindow: false,
      areChatsAvailable: true,
      chatConversationsActivityEventListener: null,
      chatConversationsEventListener: null,
      isLoadingSideBarChats: true,
      chats: [],
      allChatMessages: [],
      isDeleting: { loading: false, id: "" },
      requestsHandler: {},
      failedMessages: {},
      receivedNewMessage: {},
      newMessage: {},
    };
  },
  methods: {
    displaySelectedChat() {
      this.showMainChatWindow = true;
    },
    async getSelectedChat(chatId) {
      this.allChatMessages = [];
      this.showMainChatWindow = true;
      this.isLoading = true;
      this.selectedMessageId = chatId;

      //get all the chat messages from the endpoint
      const chatMessages = await this.requestsHandler.getData(
        `chats/help/messages/${chatId}`
      );
      this.allChatMessages = chatMessages.chat_messages;

      if (this.allChatMessages) {
        this.isLoading = false;
      }

      //find the chat with the chatId in the chats array
      const chatWithUnReadMessages = this.chats.find(
        (chat) =>
          chat.chat_conversation_id === chatId && chat.has_unread_messages
      );
      if (chatWithUnReadMessages) {
        // make a response with to the endpoint to mark the chat as read
        const response = await this.requestsHandler.patchData(
          `chats/help/mark-conversation-as-read/${chatId}`
        );
        if (response) {
          //update the chat with the new data
          const updatedChat = this.chats.find(
            (chat) => chat.chat_conversation_id === chatId
          );
          updatedChat.has_unread_messages = false;
        }
      }

      //close the event listener if it exists
      if (this.chatConversationsEventListener) {
        this.chatConversationsEventListener.close();
      }

      this.chatConversationsEventListener = new serverEventHandler(
        `chat-messages-activity-stream/${chatId}`,
        this
      );

      this.chatConversationsEventListener.listen("NEW_MESSAGE", (event) => {
        const data = JSON.parse(event.data);
        const chat = {
          ...data,
          sender: data.user,
          chat_message_id: data.enc_chat_message_id,
        };
        this.addUserChatMessage(chat);
        this.newMessage = chat;
      });

      this.chatConversationsEventListener.listen("MESSAGE_DELETED", (event) => {
        const data = JSON.parse(event.data);
        const chat = {
          ...data,
          chat_message_id: data.enc_chat_message_id,
          chat_conversation_id: data.enc_chat_conversation_id,
        };
        this.removeDeletedMessage(chat);
      });
    },
    deleteStart(args) {
      this.isDeleting.loading = true;
      this.isDeleting.id = args.id;
    },
    updateDeletedMessage(message) {
      if (message.hasFailed) {
        this.failedMessages[message.chat_message_id] = message;
        this.allChatMessages.forEach((dataArray) => {
          if (dataArray.chat_message_id === message.chat_message_id) {
            dataArray.hasFailed = message.hasFailed;
            dataArray.showDeleteFailure = true;
          }
        });
        this.isDeleting.loading = false;
        return;
      }
    },
    removeDeletedMessage(message) {
      const messageId = message?.chat_message_id;
      this.allChatMessages = this.allChatMessages.filter(
        (message) => message.chat_message_id != messageId
      );
      this.isDeleting.loading = false;
      this.failedToDeleteMessage = false;
    },
    addUserChatMessage(args) {
      // check if this.hasReceivedNewMessage is in all the chats
      const { isFound } = checkIfItemIsInArray(
        this.allChatMessages,
        args,
        "private_client_chat_id"
      );
      if (!isFound) {
        this.allChatMessages.push(args);
      }
    },
    updateUserChat(args) {
      this.allChatMessages.forEach((dataArray) => {
        if (dataArray.private_client_chat_id === args.private_client_chat_id) {
          dataArray.chat_message_id = args.chat_message_id;
          dataArray.created_at = args.created_at;
          dataArray.hasFailed = args.hasFailed;

          if (args.hasFailed) {
            this.failedMessages[args.private_client_chat_id] = args;
            dataArray.showFailedToSend = true;
          }
        }
      });
    },
    updateUserMessages(args) {
      this.allChatMessages = [...args, ...this.allChatMessages];
    },
    removeFailedMessage(id) {
      delete this.failedMessages[id];
    },

    async fetchConversations(showLoading = false) {
      if (showLoading) {
        this.isLoadingSideBarChats = true;
        this.isLoading = true;
      }
      
      
      try {
        this.chatsOffset += 50;
        let request = await this.$http.get(
          `/chats/help/conversations?offset=${this.chatsOffset}`
        );

        if (request.data.message === "HELP CONVERSATIONS FETCHED SUCCESSFULLY") {
          this.chats.push(...request.data.conversations);
          return true;
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoadingSideBarChats = false;
        this.isLoading = false;
      }
    }
  },
  async mounted() {
    this.requestsHandler = new makeApiRequest(this);
    const hasFetched = await this.fetchConversations(true);

    if(!hasFetched) return;

    //close the event listener if it exists
    if (this.chatConversationsActivityEventListener) {
      this.chatConversationsActivityEventListener.close();
    }

    this.chatConversationsActivityEventListener = new serverEventHandler( `conversations-activity-stream`, this );

    this.chatConversationsActivityEventListener.listen(
      "NEW_CONVERSATION",
      (event) => {
        const data = JSON.parse(event.data);
        const newConversation = {
          ...data,
          chat_conversation_id: data.enc_chat_conversation_id,
          last_activity_date: data.last_activity_date,
          has_unread_messages: data.has_unread_messages,
        };
        this.chats.push(newConversation);
      }
    );

    this.chatConversationsActivityEventListener.listen(
      "CONVERSATION_ACTIVITY",
      async (event) => {
        const conversation = JSON.parse(event.data);
        const unReadMessagesCount = conversation.unread_conversation_count;
        this.$store.commit("SET_UNREAD_MESSAGES_COUNT", unReadMessagesCount);
        //find the conversation in the chats and replace it with the new data
        const conversationIndex = this.chats.findIndex(
          (chat) =>
            chat.chat_conversation_id === conversation.enc_chat_conversation_id
        );

        if (
          conversationIndex > -1 &&
          conversation.enc_chat_conversation_id === this.selectedMessageId
        ) {
          // if it's the owner of the message and he/she is reading the messages mark the messages as read.
          await this.requestsHandler.patchData(
            `chats/help/mark-conversation-as-read/${this.selectedMessageId}`
          );

          const affectedConversation = {
            ...this.chats[conversationIndex],
            last_activity_date: conversation.last_activity_date,
            has_unread_messages: false,
            last_message: conversation.last_message,
          };
          //TODO:Replace this code with vue.set which doesn't seem t be working at the moment @regan

          this.chats.splice(conversationIndex, 1);
          this.chats.push(affectedConversation);

          return;
        }

        if (
          conversationIndex > -1 &&
          conversation.enc_chat_conversation_id !== this.selectedMessageId
        ) {
          const affectedConversation = {
            ...this.chats[conversationIndex],
            last_activity_date: conversation.last_activity_date,
            has_unread_messages: conversation.has_unread_messages,
            last_message: conversation.last_message,
          };

          //TODO:Replace this code with vue.set which doesn't seem t be working at the moment @regan

          this.chats.splice(conversationIndex, 1);
          this.chats.push(affectedConversation);
          return;
        }
      }
    );

    this.chatConversationsActivityEventListener.listen(
      "CONVERSATION_MARK_AS_READ",
      (event) => {
        const data = JSON.parse(event.data);
        const unReadMessagesCount = data.unread_conversation_count;
        this.$store.commit("SET_UNREAD_MESSAGES_COUNT", unReadMessagesCount);
        const conversationIndex = this.chats.findIndex(
          (chat) => chat.chat_conversation_id === data.enc_chat_conversation_id
        );
        if (conversationIndex > -1) {
          this.chats[conversationIndex].has_unread_messages = false;
        }
      }
    );
  },
  computed: {
    chatConversationsSortedByDate() {
      return sortArrayByDate(this.chats, "last_activity_date");
    },
    sortMessagesByDate() {
      return sortArrayByDate(this.allChatMessages, "created_at");
    },
  },
  watch: {
    receivedNewMessage() {
      const { isFound } = checkIfItemIsInArray(
        this.allChatMessages,
        this.receivedNewMessage
      );
      if (!isFound) {
        this.allChatMessages.push(this.receivedNewMessage);
      }
    },
    allChatMessages() {
      if (this.allChatMessages?.length > 0) {
        this.areChatsAvailable = true;
      } else {
        this.areChatsAvailable = false;
      }
    },
  },
  beforeDestroy() {
    if (this.chatConversationsActivityEventListener) {
      this.chatConversationsActivityEventListener.close();
    }
    if (this.chatConversationsEventListener) {
      this.chatConversationsEventListener.close();
    }
  },
};
</script>


<style scoped>
#main-chat-container {
  width: 100%;
  height: 95vh;
  display: flex;
  flex-direction: row;
}

.chat-container {
  flex-grow: 3;
}

.remove-on-mobile {
  display: block;
}

/* breakpoints for mobile */
@media (max-width: 768px) and (max-width: 1024px) {
  .remove-on-mobile {
    display: none;
  }
}
</style>