<template>
  <div class="active_students_dialog">
    <el-dialog
      title="Export Active Students As Excel"
      :visible.sync="showExportActiveStudentsDialog"
      :destroy-on-close="true"
      :before-close="handleClose"
    >

      <div v-loading="downloading">
        <div class="row">
          <div class="col-12">
            <div class="block w-100">
              <div class="pb-2 demonstration">Select period </div>
              <el-date-picker
                type="daterange"
                class="w-100"
                v-model="startAndEndTime"
                :format="'dd MMMM yyyy'"
                :value-format="'yyyy-MM-dd'"
                :picker-options="pickerOptions"
                range-separator="To"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                style="height: 40px !important"
              >
              </el-date-picker>
            </div>
          </div>
          <div
            class="pt-4 col-12 align-items-end"
            style="display:flex;"
          >
            <el-button
              style="background-color: var(--el-app-primary); color: white; width: 100%; height: 2.6rem;"
              class="z-depth-0"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
              :disabled="!startAndEndTime"
              @click="exportActiveStudents"
            >Export</el-button>
          </div>
        </div>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import isToday from "date-fns/isToday";
export default {
  name: "upload-export-active-students-dialog",
  props: {
    showDialogue: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      downloading: false,
      showExportActiveStudentsDialog: false,
      startAndEndTime: null,
      pickerOptions: {
        disabledDate(time) {
          //Disable if the date is greater than or equal to today
          if (isToday(time)) {
            return true;
          }

          return time.getTime() > Date.now();
        },
      },
    };
  },
  methods: {
    handleClose() {
      this.$emit("close-dialog");
    },

    async exportActiveStudents() {
      try {
        this.downloading = true;
        let request = await this.$http.get(
          `/students/excel-sheet-reports/active-students?start_date=${this.startAndEndTime[0]}&end_date=${this.startAndEndTime[1]}`
        );
        if (
          request.data.success &&
          request.data.message ==
            "ACTIVE STUDENTS REPORT GENERATED SUCCESSFULLY"
        ) {
          const link = document.createElement("a");
          link.href = request.data.link;
          link.click();
          return;
        } else throw "UNEXPECTED ERROR OCCURRED";
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        return this.showFailedMessage(
          "Unable to download Students report",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.downloading = false;
      }
    },
  },
  watch: {
    showDialogue() {
      this.showExportActiveStudentsDialog = this.showDialogue ? true : false;
    },
  },
};
</script>

<style>
.active_students_dialog .el-dialog {
  width: 30% !important;
}
</style>
