export const checkIfItemIsInArray = (array = [], args, key) => {
  let index;
  const isFound = array.find((message, i) => {
    if (message[key] === args[key]) {
      index = i;
      return message;
    }
  });

  return {
    isFound,
    index,
  };
};
