import { render, staticRenderFns } from "./manage-modules-dialog.vue?vue&type=template&id=07bbc878&scoped=true"
import script from "./manage-modules-dialog.vue?vue&type=script&lang=ts&setup=true"
export * from "./manage-modules-dialog.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./manage-modules-dialog.vue?vue&type=style&index=0&id=07bbc878&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07bbc878",
  null
  
)

export default component.exports