var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{style:({
    width: _vm.width,
    height: _vm.height,
  })},[(_vm.imageUrl)?_c('img',{staticStyle:{"object-fit":"cover","border-radius":"100%"},attrs:{"src":_vm.imageUrl,"alt":"","height":_vm.height,"width":_vm.width}}):_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","background-color":"var(--el-app-primary)","color":"white","object-fit":"cover","border-radius":"100%"},style:({
      width: _vm.width,
      height: _vm.height,
    })},[_c('strong',[_vm._v(_vm._s(_setup.getInitials))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }