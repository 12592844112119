import { render, staticRenderFns } from "./chat-component.vue?vue&type=template&id=2e6f0e1e&scoped=true"
import script from "./chat-component.vue?vue&type=script&lang=js"
export * from "./chat-component.vue?vue&type=script&lang=js"
import style0 from "./chat-component.vue?vue&type=style&index=0&id=2e6f0e1e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e6f0e1e",
  null
  
)

export default component.exports