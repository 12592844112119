<template>
  <div class="container-fluid px-0 h-100" style="text-align: left">
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent
                :pageTitles="['Sets', 'Examinations', 'Edit']"
              />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-12 text-left">
              <div
                style="font-size: 22px; font-weight: 400; margin-bottom: 15px"
              >
                <strong>Edit Examination</strong>
              </div>
            </div>
            <div class="col-md-8 select-intake-component">
              <label style="font-weight: 600">Modules and Intake</label><br />
              <el-select
                v-model="chosenCourseUnitsWithIntakes"
                multiple
                :disabled="isBusy"
                filterable
                remote
                placeholder="Enter a module"
                :remote-method="onSearchModules"
                :loading="isSearching"
              >
                <el-option
                  v-for="item in courseUnitsWithCourseIntakeBatches"
                  :key="item.course_unit_intake_batch_combined_id"
                  :label="item.course_unit_with_intake"
                  :value="item.course_unit_intake_batch_combined_id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-12 pt-5 text-left">
              <div
                style="
                  font-weight: 500;
                  margin-bottom: 15px;
                  color: rgba(0, 0, 0, 0.3);
                "
              >
                Examination Details
              </div>
            </div>

            <!-- <div class="col-md-4">
              <label style="font-weight: 600; font-size: 0.9em">Examination Type</label><br />
              <el-select
                filterable
                disabled
                v-model="examinationType"
                placeholder="Examination type"
                style="width: 100%"
              >
                <el-option
                  label="File Upload & Online"
                  value="UPLOAD"
                ></el-option>
                <el-option
                  label="Online"
                  disabled
                  value="ONLINE"
                ></el-option>
              </el-select>
            </div> -->

            <div class="col-md-4">
              <label for="title" style="font-weight: 600; font-size: 0.9em"
                >Examination Title</label
              >
              <input
                maxlength="255"
                :disabled="isBusy"
                type="text"
                style="height: 42px"
                v-model.trim="examTitle"
                placeholder="Title"
              />
            </div>

            <div class="col-md-4">
              <label for="title" style="font-weight: 600; font-size: 0.9em"
                >Total Marks</label
              >
              <input
                maxlength="4"
                :disabled="isBusy"
                type="text"
                style="height: 42px"
                v-model.trim="totalMarks"
                placeholder="Optional"
              />
            </div>

            <div class="row w-100 mx-0 mt-4">
              <div class="col-md-4">
                <label for="title" style="font-weight: 600; font-size: 0.9em"
                  >Start Time</label
                ><br />
                <el-date-picker
                  v-model="startTime"
                  :disabled="isBusy"
                  :format="'dd MMMM yyyy hh:mm A'"
                  type="datetime"
                  placeholder="Select date and time"
                  style="width: 100%"
                >
                </el-date-picker>
              </div>
              <div class="col-md-4">
                <label for="title" style="font-weight: 600; font-size: 0.9em"
                  >End Time</label
                ><br />
                <el-date-picker
                  v-model="endTime"
                  :disabled="isBusy"
                  :format="'dd MMMM yyyy hh:mm A'"
                  type="datetime"
                  placeholder="Select date and time"
                  style="width: 100%"
                >
                </el-date-picker>
              </div>

              <div class="col-md-8">
                <br />
                <label for="instructions" style="font-weight: 600"
                  ><strong
                    >Examination Instructions
                    <i style="font-weight: 400; color: rgba(0, 0, 0, 0.5)"
                      >(Optional)</i
                    ></strong
                  ></label
                ><br />
                <el-input
                  type="textarea"
                  :disabled="isBusy"
                  id="instructions"
                  v-model="examInstructions"
                  maxlength="1000"
                  :autosize="{ minRows: 5, maxRows: 10 }"
                  placeholder="Type Instructions here ..."
                >
                </el-input>
                <br />
              </div>

              <div class="col-12 pt-5 text-left">
                <div
                  style="
                    font-weight: 500;
                    margin-bottom: 15px;
                    color: rgba(0, 0, 0, 0.3);
                  "
                >
                  Upload Files
                </div>
                <div class="pb-2" style="font-size: 0.8em">
                  * Accepted File Types are DOC, DOCX, XLS, XLSX, PPT, PPTX, PDF
                  and should not be more than <nobr>100Mbs</nobr> <br />
                  * ZIP File type can also be used for Question Papers
                </div>
              </div>

              <div class="col-md-5">
                <div>
                  <label for="file" style="font-weight: 600; font-size: 0.9em"
                    ><strong>Question Paper (Optional)</strong></label
                  ><br />
                  <el-upload
                    class="upload-demo"
                    drag
                    :disabled="isBusy"
                    :action="''"
                    :file-list="examQuestionFiles"
                    :on-remove="handleQuestionRemove"
                    :on-change="handleQuestionPreview"
                    :auto-upload="false"
                    :multiple="false"
                  >
                    <div v-if="!isBusy">
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        Drop file here or <em>click to upload</em>
                      </div>
                    </div>
                    <div v-else style="margin-top: 30px">
                      <el-progress
                        v-if="questionPaperUploadingProgress != 100"
                        type="circle"
                        :color="progressColors"
                        :percentage="questionPaperUploadingProgress"
                        :width="80"
                        :stroke-width="4"
                      ></el-progress>
                      <el-progress
                        v-else
                        type="circle"
                        :percentage="100"
                        status="success"
                        :width="80"
                        :stroke-width="4"
                      ></el-progress>
                      <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                        {{
                          questionPaperUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                      </div>
                    </div>
                  </el-upload>
                </div>
              </div>

              <div class="col-md-5">
                <div>
                  <label for="file" style="font-weight: 600; font-size: 0.9em"
                    ><strong>Answer Sheet (Optional)</strong></label
                  ><br />
                  <el-upload
                    class="upload-demo"
                    drag
                    :disabled="isBusy"
                    :action="''"
                    :file-list="answerSheetsFiles"
                    :on-remove="handleAnswerRemove"
                    :on-change="handleAnswerPreview"
                    :auto-upload="false"
                    :multiple="false"
                  >
                    <div v-if="!isBusy || !selectedAnswerFile">
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        Drop file here or <em>click to upload</em>
                      </div>
                    </div>
                    <div v-else style="margin-top: 30px">
                      <el-progress
                        v-if="answerSheetUploadingProgress != 100"
                        type="circle"
                        :color="progressColors"
                        :percentage="answerSheetUploadingProgress"
                        :width="80"
                        :stroke-width="4"
                      ></el-progress>
                      <el-progress
                        v-else
                        type="circle"
                        :percentage="100"
                        status="success"
                        :width="80"
                        :stroke-width="4"
                      ></el-progress>
                      <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                        {{
                          answerSheetUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                      </div>
                    </div>
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="row w-100 mx-0 mt-3">
              <div class="col-12">
                <!-- {{this.questionPaperUploadingProgress}} -->
                <el-button
                  @click="onEditExamination"
                  v-if="!isBusy"
                  style="background-color: var(--el-app-primary); color: white"
                  class="z-depth-0 addbtn"
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  >Save Changes</el-button
                >

                <ScaleOut
                  style="margin-left: 50px"
                  v-else
                  :background="'#164B70'"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
// import { validationMixin } from "vuelidate";
import { parseISO } from "date-fns";
import BreadCrumbComponent from "../../components/bread-crumb-component";

import ScaleOut from "@/components/scale-out-component.vue";

import getMonthName from "../../helpers/getMonthName";

export default {
  components: {
    ScaleOut,
    BreadCrumbComponent,
  },
  // mixins: [validationMixin],

  data() {
    return {
      isBusy: false,
      activeStep: 0,
      totalMarks: "",
      isSearching: false,
      userHasRemovedAnswerSheet: false,
      chosenCourseUnitsWithIntakes: [],
      examQuestionFiles: [],
      answerSheetsFiles: [],
      courseUnitsWithCourseIntakeBatches: [],
      examTitle: "",
      examInstructions: "",
      examinationType: "",
      startTime: "",
      endTime: "",
      maximumAddedTime: "",
      selectedAnswerFile: "",
      selectedQuestionFile: "",
      isProctoredRecordAndReview: false,
      isProctoredLiveProctor: false,
      questionPaperUploadingProgress: 0,
      answerSheetUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
    };
  },

  computed: {
    isProctored() {
      return this.isProctoredRecordAndReview || this.isProctoredLiveProctor;
    },
    examType() {
      if (!this.isProctored) return null;
      return this.isProctoredRecordAndReview
        ? "record_review"
        : "live_proctoring";
    },
  },

  validations: {},

  mounted() {
    //Ensure the Examination Object has been passed on.
    if (this.$route.params.data) {
      this.initializeData();
    } else {
      this.$router.replace({ name: "ExaminationSets" });
    }
  },

  methods: {
    async onSearchModules(query) {
      if (query !== "" && query.length >= 3) {
        this.isSearching = true;
        try {
          let request = await this.$http.get(
            `course-units/search-course-unit?search_query=${query}`
          );
          if (
            request.data.success &&
            request.data.message == "SEARCH RESULTS RETRIEVED SUCCESSFULLY"
          ) {
            this.courseUnitsWithCourseIntakeBatches =
              request.data.search_results;
          } else {
            this.$rollbar.warning(
              "ADMIN FRONT END: Unexpected API response while getting server response",
              request.data,
              request
            );
            throw "UNEXPECTED_RESPONSE";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          // console.log(error);
          this.showFailedMessage(
            "Loding Failed",
            "Unable to search Modules now, Please try again later"
          );
        } finally {
          this.isSearching = false;
        }
      }
    },

    initializeData() {
      this.examTitle = this.$route.params.data.examination.title;
      this.totalMarks = this.$route.params.data.examination.total_marks;
      this.startTime = parseISO(
        this.$route.params.data.examination.available_from
      );
      this.endTime = parseISO(
        this.$route.params.data.examination.available_upto
      );
      this.examInstructions = this.$route.params.data.examination.instructions;

      const preparedCourseUnitsWithBatches = [];
      const preChosenCourseUnitsWithBatches = [];

      this.$route.params.data.examination.examination_intakes.forEach(
        (examination_intake) => {
          preChosenCourseUnitsWithBatches.push(
            `${examination_intake.course_intake_batch_id}_${examination_intake.course_unit_id}`
          );
          preparedCourseUnitsWithBatches.push({
            course_unit_intake_batch_combined_id: `${examination_intake.course_intake_batch_id}_${examination_intake.course_unit_id}`,
            course_unit_with_intake: `${
              examination_intake.course_unit.course_unit_code
            } (${examination_intake.course_unit.title}) in ${
              examination_intake.course_intake_batch.course.course_code
            } ${this.toMonthName(
              examination_intake.course_intake_batch.month
            ).toUpperCase()}/${examination_intake.course_intake_batch.year} ${
              examination_intake.course_intake_batch.cohort
            }`,
          });
        }
      );

      this.courseUnitsWithCourseIntakeBatches = preparedCourseUnitsWithBatches;
      this.chosenCourseUnitsWithIntakes = preChosenCourseUnitsWithBatches;

      if (this.$route.params.data.examination.question_paper_path_key) {
        this.examQuestionFiles = [
          {
            name: "Question Paper",
            url: "",
          },
        ];
      }

      if (this.$route.params.data.examination.answer_sheet_path_key) {
        this.answerSheetsFiles = [
          {
            name: "Answer Sheet",
            url: "",
          },
        ];
      }
    },

    toMonthName(monthNum) {
      return getMonthName(monthNum);
    },

    handleQuestionPreview(file) {
      this.examQuestionFiles = [file];
      this.selectedQuestionFile = file;
    },
    handleQuestionRemove() {
      this.selectedQuestionFile = null;
    },

    handleAnswerPreview(file) {
      this.answerSheetsFiles = [file];
      this.selectedAnswerFile = file;
    },
    handleAnswerRemove() {
      this.userHasRemovedAnswerSheet = true;
      this.selectedAnswerFile = null;
    },

    async onEditExamination() {
      try {
        if (!this.chosenCourseUnitsWithIntakes.length) {
          return this.showWarningMessage(
            "Modules Required",
            "Modules for this examination are required"
          );
        }

        if (!this.examTitle) {
          return this.showWarningMessage(
            "Exam Title Required",
            "The Title for this examination are required"
          );
        }
        if (this.examTitle.length > 255) {
          return this.showWarningMessage(
            "Exam Title too long",
            "The Title for this examination is too long. It should be less than 255 Characters"
          );
        }
        if (
          this.totalMarks &&
          (isNaN(+this.totalMarks) ||
            +this.totalMarks > 100 ||
            +this.totalMarks <= 0)
        ) {
          return this.showWarningMessage(
            "Total Marks Invalid",
            "When supplied, total marks should be a number between 0 and 100"
          );
        }

        if (!this.startTime || !this.endTime) {
          return this.showWarningMessage(
            "Exam Start & End Time Required",
            "The Exam Start & End Time are both Required "
          );
        }

        if (+this.startTime >= +this.endTime) {
          return this.showWarningMessage(
            "Invalid Time",
            "The End Time can not be before or equal to the Start Time, please review it and try again."
          );
        }

        if (this.examInstructions && this.examInstructions.length > 1000) {
          return this.showWarningMessage(
            "Instructions too Long",
            "Examination Instructions are too long. It should be less than 1000 Characters"
          );
        }

        //If the exam is proctored, then ensure it is only for max of 3 Hours
        if (this.isProctored) {
          let examLength = this.endTime.getTime() - this.startTime.getTime();

          if (examLength > 3600000 * 3) {
            return this.showWarningMessage(
              "Proctored Exam Length",
              "The Length of a Proctored Exam can not be more than 3 Hours."
            );
          }
        }

        //If the user selected a new question paper, then ensure it's of accepted Size
        if (
          this.selectedQuestionFile &&
          this.selectedQuestionFile.raw.size > 100 * 1024 * 1024
        ) {
          return this.showWarningMessage(
            "File Too Big",
            "The Question Paper File you are trying to upload is too Big. Please choose another one below 100Mbs."
          );
        }

        //If answer sheet is supplied, Ensure it is not more than 100Mbs
        if (
          this.selectedAnswerFile &&
          this.selectedAnswerFile.size > 100 * 1024 * 1024
        ) {
          return this.showWarningMessage(
            "File Too Big",
            "The Answer Sheet File you are trying to upload is too Big. Please choose another one below 100Mbs."
          );
        }

        this.isBusy = true;

        //Since all the data is valid, if the user has chosen a new file for question paper or answer sheet

        let uploadUrls;
        if (this.selectedQuestionFile || this.selectedAnswerFile) {
          uploadUrls = await this.requestUploadUrls({
            shouldUploadQuestionPaper: !!this.selectedQuestionFile,
            shouldUploadAnswerSheet: !!this.selectedAnswerFile,
          });
        }

        //If there's any upload URL requested, then upload any
        let questionPaperPathUrl, answerSheetPathUrl;
        if (uploadUrls) {
          if (uploadUrls.question_paper) {
            // Upload the Question Paper
            questionPaperPathUrl = await this.uploadResourceToAWS({
              aws_upload_url: uploadUrls.question_paper.upload_url,
              selected_file: this.selectedQuestionFile.raw,
              onProgress: (progressEvent) => {
                this.questionPaperUploadingProgress = +(
                  (progressEvent.loaded / progressEvent.total) *
                  100
                ).toFixed(0);
              },
            });
          } else {
            this.questionPaperUploadingProgress = 100;
          }

          if (uploadUrls.answer_sheet) {
            //If the user chose an answer sheet too, upload it
            answerSheetPathUrl = await this.uploadResourceToAWS({
              aws_upload_url: uploadUrls.answer_sheet.upload_url,
              selected_file: this.selectedAnswerFile.raw,
              onProgress: (progressEvent) => {
                this.answerSheetUploadingProgress = +(
                  (progressEvent.loaded / progressEvent.total) *
                  100
                ).toFixed(0);
              },
            });
          }
        }

        //We can now set the uploaded statuses of all the files to 100 and proceed with the edit
        this.answerSheetUploadingProgress = 100;

        let request = await this.$http.patch(
          `examinations/edit/${this.$route.params.data.examination.examination_id}`,
          {
            title: this.examTitle,
            total_marks: this.totalMarks,
            question_paper_path_url: this.selectedQuestionFile
              ? questionPaperPathUrl
              : undefined,
            question_paper_path_key: this.selectedQuestionFile
              ? uploadUrls.question_paper.path_key
              : undefined,
            answer_sheet_path_url: this.selectedAnswerFile
              ? answerSheetPathUrl
              : undefined,
            answer_sheet_path_key: this.selectedAnswerFile
              ? uploadUrls.answer_sheet.path_key
              : undefined,
            available_from: this.startTime,
            available_upto: this.endTime,
            instructions: this.examInstructions,
            question_paper_mime_type: this.selectedQuestionFile
              ? this.selectedQuestionFile.raw.type
              : undefined,
            course_units_and_batches: this.chosenCourseUnitsWithIntakes.map(
              (item) => {
                /* Since the Id is intentionally concatenated with the batch and course unit separated by an underscore
              since an exam can be on the same intake but multiple course units, it causes vue v-for duplication errors
              but the combination of the intake and course unit is always unique
            we split it before we send to the API */
                const itemParts = item.split("_");
                return {
                  course_intake_batch_id: itemParts[0],
                  course_unit_id: itemParts[1],
                };
              }
            ),
            //If the user removed the answer sheet and did not choose another one, means they are now removing an answer sheet that
            //probably they had accidentally added before. So we gotta tell the Backend that the user has removed the answersheet
            //intentionally such that it doesnot misinterprete it's abscence as no change..
            should_remove_answer_sheet:
              this.userHasRemovedAnswerSheet && !this.selectedAnswerFile,
          }
        );

        if (
          request.data.success &&
          request.data.message == "EXAMINATION EDITED SUCCESSFULLY"
        ) {
          this.showSuccessMessage(
            "Examination Updated",
            "The Examination was successfully updated."
          );
          this.$router.push({
            path: `/examinations/${this.$route.params.examination_set_id}`,
          });
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        if (error.response) {
          if (error.response.data.message == "REQUIRED PARAMS MISSING") {
            this.$rollbar.warning(
              "ADMIN FRONT END: Required Params were missing"
            );
            return this.showFailedMessage(
              "Incomplete Info",
              "Incomplete Data submitted. Please try again"
            );
          } else if (
            error.response.data.message == "EXAMINATION SET IS ARCHIVED"
          ) {
            return this.showWarningMessage(
              "Archived Exam",
              "You can no longer make changes to this exam in the Examination set as it's already archived."
            );
          } else if (
            error.response.data.message == "TOTAL MARKS SHOULD BE A NUMBER"
          ) {
            return this.showWarningMessage(
              "Total Marks Invalid",
              "Total marks should be a valid number. Please review it and try again"
            );
          } else if (
            error.response.data.message ==
            "UNSUPPORTED QUESTION PAPER FILE TYPE"
          ) {
            return this.showWarningMessage(
              "Unsupported file type",
              "The Question Paper file type you submitted is not accepted"
            );
          } else if (
            error.response.data.message == "UNSUPPORTED ANSWER SHEET FILE TYPE"
          ) {
            return this.showWarningMessage(
              "Unsupported file type",
              "The Answer Sheet file type you submitted is not accepted"
            );
          } else if (
            error.response.data.message ==
            "AVAILABLE-UPTO DATE SHOULD BE VALID AND IN THE FUTURE"
          ) {
            return this.showWarningMessage(
              "Available Upto Date",
              "Available Upto Date should be a validate date and can not be in the past"
            );
          } else if (
            error.response.data.message ==
            "AVAILABLE-UPTO DATE SHOULD BE AFTER AVAILABLE-FROM AND NOT BEFORE"
          ) {
            return this.showWarningMessage(
              "Invalid Time",
              "The End Time can not be before or equal to the Start Time, please review it and try again."
            );
          } else {
            this.$rollbar.warning(
              "ADMIN FRONT END: Unexpected 400 API response while getting server response",
              error.response.data,
              error.response
            );

            return this.showWarningMessage(
              "Operation Failed",
              "Unable to complete operation now. Please try again"
            );
          }
          // return Promise(error);
        } else {
          this.showFailedMessage(
            "Unable to edit Examination",
            "An unexpected error occurred, please try again"
          );
        }
      } finally {
        this.isBusy = false;
        this.questionPaperUploadingProgress = 0;
        this.answerSheetUploadingProgress = 0;
      }
    },

    async uploadResourceToAWS({ aws_upload_url, selected_file, onProgress }) {
      this.isUploadingFile = true;
      try {
        const formData = new FormData();
        Object.entries(aws_upload_url.fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", selected_file);
        this.isUploadingFile = true;
        let request = await this.$http.post(`${aws_upload_url.url}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: " ",
          },
          onUploadProgress: onProgress,
          // onUploadProgress: (progressEvent) => console.log(progressEvent),
        });

        if (request.status == 204) {
          return request.headers.location;
        } else {
          throw "UNABLE TO UPLOAD FILE TO AWS";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.isUploadingFile = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.isUploadingFile = false;
        this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload Resource Now, please try again"
        );
      } finally {
        // this.isUploadingFile = false;
      }
    },

    async requestUploadUrls({
      shouldUploadQuestionPaper,
      shouldUploadAnswerSheet,
    }) {
      //If we should upload none of the two, then just dont even make the API request
      if (!shouldUploadQuestionPaper && !shouldUploadAnswerSheet) return null;

      let request = await this.$http.post(
        "examinations/request-for-file-upload-urls",
        {
          examination_id: this.$route.params.data.examination.examination_id,
          ...(shouldUploadQuestionPaper && {
            question_paper: {
              file_name: this.selectedQuestionFile.raw.name,
              file_size: this.selectedQuestionFile.raw.size,
              mime_type: this.selectedQuestionFile.raw.type,
            },
          }),
          ...(shouldUploadAnswerSheet && {
            answer_sheet: {
              file_name: this.selectedAnswerFile.raw.name,
              file_size: this.selectedAnswerFile.raw.size,
            },
          }),
        }
      );

      if (
        request.data.success &&
        request.data.message == "UPLOAD URLS GENERATED"
      ) {
        return {
          question_paper: request.data.question_paper,
          answer_sheet: request.data.answer_sheet,
        };
      } else {
        this.$rollbar.warning(
          "ADMIN FRONT END: Unexpected API response while getting server response",
          request.data,
          request
        );
        throw "UNEXPECTED_RESPONSE";
      }
    },
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

.select-intake-component input.el-select__input {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.announcement_dialogs .el-dialog {
  width: 70%;
}

.search_by_input {
  width: 400px !important;
}

.announcement_dialogs .el-select .el-input__inner {
  cursor: pointer;
  height: 36px !important;
  width: 100% !important;
  padding-left: 10px !important;
  text-transform: capitalize;
}
.announcement_dialogs .el-button--primary {
  color: #fff;
  background-color: #409eff !important;
  border-color: #409eff !important;
}

el-table th > .cell {
  color: black !important;
  font-weight: 500;
}
.el-table .cell {
  font-size: 0.9em;
  line-height: 23px;
  font-weight: 300;
}

.announcement_dialogs .el-input input {
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.announcement_dialogs .el-input input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

.announcement_dialogs .el-upload-dragger {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 366px !important;
  height: 150px !important;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.announcement_dialogs .el-icon-upload:before {
  font-size: 50px;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .announcement_dialogs .el-dialog {
    width: 50%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .announcement_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .announcement_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .announcement_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
