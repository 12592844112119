<template>
  <div class="col-12 pb-3 px-0 text-left">
    <div
      v-loading="isBusy"
      class="card card-body"
      :style="{
        backgroundColor: event.type == 'Lecture' ? '#d7d7f3' : '#FFD6C4',
      }"
      style="cursor: pointer"
    >
      <i
        v-if="event.data.class_status == 'Attended'"
        class="el-icon-check color-green"
        style="font-size: 20px; position: absolute; right: 10px; top: 10px"
      ></i>
      <i
        v-else-if="event.data.class_status == 'Missed'"
        class="el-icon-close color-red"
        style="font-size: 20px; position: absolute; right: 10px; top: 10px"
      ></i>
      <div
        class=""
        style="font-weight: 500; color: rgba(0, 0, 0, 0.5); font-size: 0.8em"
      >
        {{ event.type }}
      </div>
      <div
        class=""
        style="font-weight: 600; color: var(--el-app-primary); font-size: 0.9em"
      >
        {{ event.data.title }}
      </div>
      <div class="mt-2" style="font-weight: 600; color: #000; font-size: 0.8em">
        {{ formattedDate(event.data.date) }}
      </div>
      <div style="font-weight: 600; color: #000; font-size: 0.8em">
        {{ `${event.data.start_time} - ${event.data.end_time}` }}
      </div>
      <div
        class=""
        style="font-weight: 500; color: rgba(0, 0, 0, 0.4); font-size: 0.8em"
      >
        By {{ event.data.lecturer.first_name }}
        {{ event.data.lecturer.last_name }}
      </div>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
export default {
  data() {
    return {
      isBusy: false,
    };
  },
  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMMM yyyy");
    },
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {},
};
</script>

<style scoped>
.color-red {
  color: red !important;
}

.color-green {
  color: rgb(7, 206, 7) !important;
}
</style>
