<template>
  <div class="text-left upload-content">
    <div class="container mt-5">
      <div class="row w-100 mb-4">
        <div class="col-12 pl-3 px-0 mb-0 text-left">
          <BreadCrumbComponent :pageTitles="['Resources', 'Upload module resources']" />
        </div>
      </div>

      <h1
        style="font-size: 1.5em; font-weight: 600"
        class="mb-4"
      >
        Update {{ title ? `"${title}"` : "Resource" }}
      </h1>
      <div v-loading="isUploadingFile">
        <div class="row">
          <div
            class="col-md-4 pb-3"
            style="color: rgba(0, 0, 0, 0.8)"
          >
            <span style="font-size: 1.2em; font-weight: 500">Resource Type</span>
            <div class="mt-1">
              <el-select
                v-model="$v.selectedResourceType.$model"
                placeholder="Select"
                class="d-block"
              >
                <el-option
                  v-for="item in resourceTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value.toLowerCase()"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div
            class="col-md-4 pb-3"
            style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
          >
            <span style="font-size: 1.2em; font-weight: 500">Resource Title</span>
            <span
              v-show="$v.title.$error && !$v.title.required"
              class="pl-2"
              style="color: red; font-size: 0.8em"
            >Required</span>
            <span
              v-show="$v.title.$error && !$v.title.maxLength"
              class="pl-2"
              style="color: red; font-size: 0.8em"
            >Too Long</span>
            <div class="mt-1">
              <input
                type="text"
                placeholder="Resource Title"
                :class="{ 'input-error': $v.title.$error }"
                v-model.trim="$v.title.$model"
                class="legacy-input bg-white"
                style="width: 100%; height: calc(2.55rem) !important"
              />
            </div>
          </div>

          <div
            class="col-md-4 pb-3"
            style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
          >
            <span
              class="demonstration"
              style="font-size: 1.2em; font-weight: 500"
            >Publish Date</span>
            <div class="mt-1">
              <el-date-picker
                v-model="$v.maturity_date.$model"
                :format="'dd MMMM yyyy hh:mm A'"
                type="datetime"
                placeholder="Select date and time"
                style="width: 100%"
              >
              </el-date-picker>
            </div>
            <br />
            <!-- <span v-if="$v.valid_upto.$error" style="font-size: 0.7em; color: red">{{ valid_uptoError }}</span> -->
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div>

              <h2 style="font-size: 1.2em; font-weight: 500">Select Cohorts</h2>
              <hr />
              <!-- {{ selectedCohorts | json }} -->
              <!-- <el-checkbox
                            :indeterminate="isIndeterminate"
                            v-model="checkAll"
                            @change="handleCheckAllChange"
                            >Check all
                        </el-checkbox> -->

              <div style="margin: 15px 0"></div>
              <div
                class="mb-5"
                v-for="cohort in selectedCohorts"
                :key="cohort.course_intake_batch_id"
              >
                <h3 style="font-size: 1.0em; font-weight: 500">{{ cohort.intake }}</h3>
                <el-checkbox-group
                  v-model="checkedModules"
                  class="row w-100 mx-0"
                >
                  <el-checkbox
                    v-for="units in cohort.intake_course_units"
                    :label="`${cohort.course_intake_batch_id}_${units.course_unit_id}`"
                    :key="units.course_unit_id"
                    class="col-lg-6 mx-0"
                  >
                    {{ units.course_unit_code}} - {{ units.title }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>

        <div class="row w-100 mt-5">
          <div class="col-12 text-center px-3">
            <button
              type="button"
              :disabled="$v.$invalid"
              :class="{ 'not-allowed': $v.$invalid }"
              class="btn z-depth-0 w-100 mx-0"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
              style="
                text-transform: capitalize;
                height: 45px;
                width: 170px !important;
                border-radius: 4px;
                background-color: var(--el-app-primary);
              "
              @click="editResource"
            >
              <span style="position: relative; bottom: 1px">Save Changes</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import BreadCrumbComponent from "../components/bread-crumb-component";
export default {
  mixins: [validationMixin],
  components: { BreadCrumbComponent },
  data() {
    return {
      selectedCohorts: this.$route.params.selectedCohorts,
      resourceId: this.$route.params.resourceId,

      selectedFile: null,
      isDurationError: false,
      loading: false,
      loadingError: false,
      isUploadingFile: false,
      selectedResourceType: "",
      title: "",
      maturity_date: "",
      fileLists: [],

      resourceTypes: [
        { value: "notes", label: "Notes" },
        { value: "video", label: "Videos" },
        { value: "past-papers", label: "Past Papers" },
      ],

      checkAll: false,
      checkedModules: [],
      isIndeterminate: true,

      resourceRuleForm: {
        programmes: "",
        module_cohort: "",
      },

      resourceRules: {
        programmes: [
          {
            required: true,
            message: "Please select programmes",
            trigger: "change",
          },
        ],
        module_cohort: [
          {
            required: true,
            message: "Please choose resource target.",
            trigger: "change",
          },
        ],
      },

      module_cohort: "",
      programmes: [],
    };
  },

  validations: {
    selectedResourceType: { required },
    title: { required, maxLength: maxLength(100) },
    maturity_date: {},
  },

  computed: {
    // courseUnits() {
    //   return this.selectedCohorts.map((cunit) => {
    //     return cunit.course_units;
    //   });
    // },
    // modulesIds() {
    //   return this.courseUnits.map((module) => {
    //     return module.course_unit_id;
    //   });
    // },
  },

  created() {
    if (Object.keys(this.$route.params).length > 0) {
      // this.attendanceInfo = this.$route.params.report.attendance;
      // this.lecture_info = this.$route.params.report.lecture_info;
    } else return this.$router.replace({ name: "Resources" });
  },

  mounted() {
    this.getResourceById();
  },

  methods: {
    handleClose(done) {
      done();
    },
    handlePreview(file) {
      this.fileLists = [file];
      this.selectedFile = file;
    },
    handleRemove() {
      this.selectedFile = null;
    },
    // handleCheckAllChange(val) {
    // this.checkedModules = val ? this.selectedCohorts : [];
    // this.isIndeterminate = false;
    // },
    // handlecheckedModulesChange(value) {
    // let checkedCount = value.length;
    // this.checkAll = checkedCount === this.cities.length;
    // this.isIndeterminate =
    //     checkedCount > 0 && checkedCount < this.cities.length;
    // },

    async getResourceById() {
      try {
        this.isUploadingFile = true;
        this.loadingError = false;

        let request = await this.$http.get(
          `resources/fetch-one/${this.resourceId}`
        );

        if (
          request.data.success &&
          request.data.message == "RESOURCE_SUCCESSFULLY_RETRIEVED"
        ) {
          this.selectedResourceType = request.data.resource.resource_type;
          this.title = request.data.resource.title;
          this.maturity_date = request.data.resource.maturity_date;

          let course_unit_with_intake_resources =
            request.data.resource.course_unit_with_intake_resources;

          this.checkedModules = course_unit_with_intake_resources.map(
            (item) => {
              return `${item.course_intake_batch_id}_${item.course_unit_id}`;
            }
          );
          // alert(checkedIds) `${cohort.course_intake_batch_id}_${units.course_unit_id}
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
           this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load Cohorts",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.isUploadingFile = false;
      }
    },

    async editResource() {
      if (this.$v.$invalid) return;
      
        //Ensure it is not more than 100Mbs
        if (!this.checkedModules.length) {
          return this.showWarningMessage(
            "No Modules Chosen",
            "Did you forget to Choose some modules for this resource?"
          );
        }

      try {
        //Since checked modules are arrays of Ids and we require an array of objects, we
        //need to prepare an array of objects to give the API
        const preparedCourseUnitsWithIntakes = [];

        //Since the same course unit can be used on multiple intakes, to ensure
        //uniqueness in chosen modules, we prefixed it with the intake id
        //WHich in subsequent operations, we need to remove the prefix before sending to the API
        this.checkedModules.forEach((checkedModule) => {
          this.selectedCohorts.forEach((selectedCohort) => {
            const hasMatch = selectedCohort.intake_course_units.some(
              (courseUnit) =>
                `${selectedCohort.course_intake_batch_id}_${courseUnit.course_unit_id}` ==
                checkedModule
            );
            if (hasMatch) {
              preparedCourseUnitsWithIntakes.push({
                course_unit_id: checkedModule.replace(
                  `${selectedCohort.course_intake_batch_id}_`,
                  ""
                ),
                course_intake_batch_id: selectedCohort.course_intake_batch_id,
              });
            }
          });
        });

        console.log(preparedCourseUnitsWithIntakes);

        let uploadEditData = {
          title: this.title,
          resource_target: "COURSE_UNIT_WITH_COURSE_INTAKE_BATCH",
          course_units_and_batches: preparedCourseUnitsWithIntakes,
          maturity_date: this.maturity_date,
        };

        this.isUploadingFile = true;
        let request = await this.$http.patch(
          `resources/update-resource-info/${this.resourceId}`,
          uploadEditData
        );

        if (
          request.data.success &&
          request.data.message == "RESOURCE_INFORMATION_UPDATED"
        ) {
          this.showSuccessMessage("Success", "Resource updated successfully");
          this.$router.push({ path: "/dashboard/resources" });
        } else if (
          request.data.message == "MATURITY_DATE_CANT_BE_IN_THE_PAST"
        ) {
          this.isUploadingFile = false;
          this.showWarningMessage(
            "Past Date",
            "Resource can not be added in the past date."
          );
        } else if (request.data.message == "UNSUPPORTED_FILE_TYPE") {
          this.isUploadingFile = false;
          this.showWarningMessage(
            "Unsupported file",
            "The file you are trying to upload is not supported."
          );
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.isUploadingFile = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.isUploadingFile = false;
        this.showFailedMessage(
          "Upload Failed",
          "Unable to Update Resource Now, please try again"
        );
      } finally {
        // this.isUploadingFile = false;
      }
    },

    clearInputs() {
      this.selectedResourceType = "";
      this.title = " ";
    },

    doResourceTypeValidation(request, fileType) {
      let result = true;
      if (
        ["notes", "course-works", "past-papers"].includes(
          request.body.resource_type.toLowerCase().trim()
        )
      ) {
        if (
          ![
            "DOC",
            "DOCX",
            "XLS",
            "XLSX",
            "PPT",
            "PPTX",
            "ODT",
            "ODS",
            "ODP",
            "PDF",
            // "TXT",
          ].includes(fileType.toUpperCase())
        ) {
          result = false;
        }
      } else if (request.body.resource_type.trim() === "video") {
        if (
          !["mp4", "ts", "webmv", "avi", "mpeg", "flv", "av"].includes(
            request.body.resource_type.trim().toLowerCase()
          )
        ) {
          result = false;
        }
      }
      return result;
    },
  },
};
</script>

<style>
.input-error {
  border: 1px solid red !important;
  box-shadow: none !important;
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /*  animation-iteration-count:  infinite;  */
}
.not-allowed {
  cursor: not-allowed !important;
  pointer-events: all !important;
}
select.browser-default {
  display: block !important;
  height: 30px !important;
  width: 105%;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  cursor: pointer;
}
.upload-content .el-dialog__title {
  font-weight: 500;
}

.upload-content .el-select .el-input__inner {
  cursor: pointer;
  height: 40px !important;
  width: 100% !important;
  padding-left: 10px !important;
}

.el-input--prefix .el-input__inner {
  padding-left: 30px !important;
  height: 40px !important;
}

.el-date-editor--date .el-input__inner,
.el-range-editor .el-input__inner {
  cursor: pointer;
  height: 40px !important;
  width: 100% !important;
  padding-left: 45px !important;
}
.el-date-editor .el-range-separator {
  display: contents;
  font-size: 0.8em;
  width: 20px !important;
  white-space: nowrap;
}

.el-date-editor.el-range-editor.el-input__inner.el-date-editor--timerange {
  height: 45px !important;
  width: 100%;
}
</style>