<template>

  <div class="chat-container">

    <div
      class="select-chat"
      v-if="!showMainChatWindow"
    >
      <div class="show-on-mobile">
        <span>Click on the messages icon select a chat.</span>

        <i
          class="el-icon-chat-line-square"
          @click="showDrawer"
          id="show-messages-button"
        >
        </i>
        <el-drawer
          title="Messages"
          :visible.sync="isDrawerOpen"
          :with-header="false"
          size="70%"
        >
          <ChatSideBar
            :chats="chats"
            :isDrawerOpen="isDrawerOpen"
            :selectedMessageId="selectedMessageId"
            :isLoadingSideBarChats="isLoadingSideBarChats"
            @close-drawer="closeDrawer"
          />
        </el-drawer>

      </div>

      <div class="remove-on-mobile">
        Select a chat to get started.
      </div>
      <img
        src="../../assets/img/selected_option.svg"
        alt="start conversation image"
        srcset=""
        id="select-chat-image"
      >
    </div>

    <div
      class="chat-header-title"
      v-if="showMainChatWindow"
    >
      <span class="chat-header">
        Admin Inquiries Chat
      </span>
      <i
        class="el-icon-chat-line-square"
        @click="showDrawer"
        id="show-messages-button"
      ></i>

      <el-drawer
        title="Messages"
        :visible.sync="isDrawerOpen"
        :with-header="false"
        size="70%"
      >
        <ChatSideBar
          :chats="chats"
          :isDrawerOpen="isDrawerOpen"
          :selectedMessageId="selectedMessageId"
          :isLoadingSideBarChats="isLoadingSideBarChats"
          @close-drawer="closeDrawer"
        />
      </el-drawer>
    </div>

    <div
      id="chat-messages-container"
      ref="chatMessagesContainer"
      v-on:scroll="onScroll"
      v-if="showMainChatWindow"
    >
      <div
        class="chat-messages"
        :class="{'messages-available':areChatsAvailable}"
      >

        <div
          class="new-message-indicator clickable"
          @click="showNewMessage"
          v-if="newChatMessage"
        >
          <span class="new-message-indicator-text">
            <i class="el-icon-bottom new-message-indicator-text"></i> {{ newMessageCount }} New message(s)
          </span>
        </div>

        <div
          class="chat-message"
          :class="[checkIfIsOwner(message) ? 'sender-position' : 'receiver-position'
          ]"
          v-for="(message,index) in messages"
          :key="index"
          @mouseenter="handleDeleteOnHover(index)"
          @mouseleave="removeDeleteButton(index)"
        >
          <div
            class="chat-message-content"
            :class="[message.hasFailed && !failedToDeleteMessage?'clickable':'']"
            ref="chatMessageContent"
            @click="resendFailedMessage(message.private_client_chat_id)"
          >
            <div
              class="chat-message-content-text"
              :class="[checkIfIsOwner(message)  ? 'sender-bg-color position-end' : 'receiver-bg-color position-start',
                (isDeleting.loading && isDeleting.id == message.chat_message_id)?'deleting-color':''
              ]"
            >
              <span
                class="sender-first-name"
                v-if="!checkIfIsOwner(message)"
              >{{ message.sender && message.sender.first_name }} {{message.sender && message.sender.last_name}}</span>
              <span>
                {{ message.message }}
                <i
                  class="el-icon-warning icon_container error-color"
                  v-if="message.hasFailed"
                ></i>
              </span>
            </div>
            <div
              class="chat-message-content-date"
              :class="[checkIfIsOwner(message)  ? 'position-end' : 'position-start']"
            >
              <span
                class="
              icon_container
              pr-2"
                v-if="!message.chat_message_id ||
                (isDeleting.loading && isDeleting.id == message.chat_message_id)"
              >
                <i
                  class="el-icon-loading"
                  v-if="!message.hasFailed && !failedToDeleteMessage"
                ></i>
              </span>
              <div
                class="resend-message"
                v-if="message.hasFailed"
              >
                <i class="el-icon-refresh icon_container error-color position-end"></i>
                <span>
                  <span v-if="message.showDeleteFailure">Failed to delete</span>
                  <span v-if="message.showFailedToSend">Tap to send again</span>
                </span>
              </div>
              <span
                class="icon_container sent-icon-color pr-2"
                v-if="checkIfIsOwner(message) && message.chat_message_id && !message.hasFailed"
              >
                <i class="el-icon-circle-check"></i>
              </span>
              <span
                class="date-title"
                v-if="!message.hasFailed"
              >{{ convertDateToString(message) }} </span>

              <span
                :class="[showDeleteButtonId == index && checkIfIsOwner(message) && !failedToDeleteMessage?'show':'hidden']"
                class="delete-btn"
                @click="deleteMessage(message)"
              >
                <i class="el-icon-delete error-color"></i>
              </span>

            </div>
          </div>

        </div>

        <div
          class="d-flex align-items-center justify-content-center"
          style="height:80vh;"
          v-if="(isLoading || isScrollTopLoading) && !isDoneFetchingAllMessages"
        >
          <ScaleOut
            :background="'#1F4EA1'"
            class="mx-auto"
          />
        </div>
        <div
          v-if="isDoneFetchingAllMessages"
          id="loaded-chats"
        >
          <span>No Older Messages</span>
        </div>
      </div>

    </div>
    <div
      id="chat-input-container"
      v-if="showMainChatWindow"
    >
      <input
        type="text"
        id="chat-input"
        class="chat-input-text"
        placeholder="Type a message..."
        v-model.trim="textInput"
        @keyup.enter="sendMessage"
        ref="chatInput"
        autocomplete="off"
        maxlength="500"
      >
      <button
        id="chat-send-button"
        class="send-input-button mt-0 mt-md-3"
        @click="sendMessage"
      >

        <i
          class="fas fa-paper-plane "
          style="color: #000; font-size: 16px"
        ></i>
      </button>
    </div>
  </div>
</template>

<script>
import makeApiRequest from "../helpers/request-handlers/requestsHandler";
import ScaleOut from "../scale-out-component.vue";
import { throttle } from "throttle-debounce";
import ChatSideBar from "./chat-side-bar-component.vue";
let requestsHandler;

export default {
  name: "chat",
  props: {
    messages: {
      type: Array,
      default: () => [],
      required: true,
    },
    areChatsAvailable: {
      type: Boolean,
      default: false,
      required: true,
    },
    chats: {
      type: Array,
      default: () => [],
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: true,
    },
    failedMessages: {
      type: Object,
      default: () => {},
      required: true,
    },
    failedToDeleteMessage: {
      type: Boolean,
      default: false,
      required: true,
    },
    isDeleting: {
      type: Object,
      default: () => {},
      required: true,
    },
    selectedMessageId: {
      type: String,
      default: "",
      required: true,
    },
    showMainChatWindow: {
      type: Boolean,
      default: false,
      required: true,
    },
    newMessage: {
      type: Object,
      default: () => {},
      required: true,
    },
    isLoadingSideBarChats: {
      type: Boolean,
    },
  },
  components: { ScaleOut, ChatSideBar },
  data() {
    return {
      textInput: "",
      showDeleteButtonId: 0,
      newMessageCount: 0,
      scrollChatScrollTop: 0,
      isDrawerOpen: false,
      isScrollTopLoading: false,
      scrollChatToBottom: true,
      newChatMessage: false,
      isDoneFetchingAllMessages: false,
      newMessagesFromTheApi: [{}],
    };
  },
  methods: {
    closeDrawer(chatId) {
      this.showDrawer();
      this.$emit("chat-selected", chatId);
    },
    handleDeleteOnHover(i) {
      this.showDeleteButtonId = i;
    },
    showNewMessage() {
      this.scrollToBottom();
      this.newChatMessage = false;
      this.newMessageCount = 0;
    },
    removeDeleteButton() {
      this.showDeleteButtonId = -1;
    },
    async deleteMessage(message) {
      const messageId = message?.chat_message_id;
      await requestsHandler.deleteData(
        `chats/help/message/${messageId}`,
        message
      );
    },
    async resendFailedMessage(id) {
      const failedMessage = this.failedMessages[id];
      if (failedMessage) {
        const res = await requestsHandler.postData(
          `/chats/staff/help/send-message/${this.selectedMessageId}`,
          failedMessage
        );
        if (res) {
          this.$emit("remove-failed-message", id);
        }
        return;
      }
      return;
    },
    throttledOnScrollFunction() {
      //gets whether the scroll is at the bottom because the div are flex column reversed
      return throttle(250, async () => {
        const scrollTop = this.$refs?.chatMessagesContainer?.scrollTop;
        const scrollHeight = this.$refs?.chatMessagesContainer?.scrollHeight;
        const offsetHeight = this.$refs?.chatMessagesContainer?.offsetHeight;
        let hasScrolledToTop;

        const totalScrollTopOffSet = offsetHeight - scrollTop;
        // get the positive value of the topDifference
        // this fixes the mobile bug where mobile devices scroll to the top of the page is off by a few pixels
        const topDifference = Math.abs(totalScrollTopOffSet - scrollHeight);

        if (topDifference <= 5) {
          hasScrolledToTop = true;
        }
        this.scrollChatToBottom = false;
        this.scrollChatScrollTop = scrollTop;
        // disable the new message notification when the user scrolls to the bottom
        if (scrollTop >= -60) {
          this.newChatMessage = false;
          this.newMessageCount = 0;
        }

        if (hasScrolledToTop && !this.isDoneFetchingAllMessages) {
          // show the loader
          this.isScrollTopLoading = true;
          await this.getMoreMessagesOnScrollTop();
        }
      });
    },
    onScroll() {
      //cool function to handle throttling of scroll events
      this.throttledOnScrollFunction()();
    },
    scrollHalfway() {
      this.$refs.chatMessagesContainer.scrollTop =
        this.$refs.chatMessagesContainer.scrollHeight / 3.5;
    },
    scrollToBottom() {
      if (this?.$refs?.chatMessagesContainer) {
        this.$refs.chatMessagesContainer.scrollTop = 0;
      }
    },
    async getMoreMessagesOnScrollTop() {
      if (this.messages && !this.isDoneFetchingAllMessages) {
        const offset =
          this.messages[this.messages.length - 1] &&
          this.messages[this.messages.length - 1]["chat_message_id"];
        if (offset) {
          const moreMessages = await requestsHandler.getData(
            `chats/help/messages/${this.selectedMessageId}?offset=${offset}`
          );
          const newMessages = moreMessages?.chat_messages;
          this.newMessagesFromTheApi = newMessages;
          this.$emit("gotMoreMessages", newMessages);
          this.isScrollTopLoading = false;
          return;
        }
      }
    },
    checkIfIsOwner(message) {
      return message?.sender?.is_owner ? true : false;
    },
    async sendMessage() {
      if (this.textInput === "") return;
      if (this.textInput.length <= 500) {
        let currentDate = new Date();
        const messageObject = {
          sender: {
            is_owner: true,
          },
          private_client_chat_id: currentDate.getTime(),
          message: this.textInput,
          created_at: currentDate.toLocaleString(),
          chat_message_id: "",
          hasFailed: false,
        };
        this.textInput = "";

        this.$emit("add-message", messageObject);
        // scroll to bottom after a user sends a message
        this.scrollToBottom();

        await requestsHandler.postData(
          `/chats/staff/help/send-message/${this.selectedMessageId}`,
          messageObject
        );
      } else {
        this.showWarningMessage(
          "Wrong Input",
          "The message character limit is 500"
        );
      }
    },
    showDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
    },
    convertDateToString({ created_at }) {
      const date = new Date(created_at);
      if (date.toString() === "Invalid Date") return;
      const options = {
        weekday: "short",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return (
        date.toLocaleDateString("en-US", options) +
        ". " +
        date.toLocaleTimeString()
      );
    },
  },
  mounted() {
    this.$refs?.chatInput?.focus();
    requestsHandler = new makeApiRequest(this);
  },
  updated() {
    if (this.scrollChatToBottom) {
      this.scrollToBottom();
    }
  },
  watch: {
    newMessagesFromTheApi() {
      if (this.newMessagesFromTheApi.length > 0) {
        this.isDoneFetchingAllMessages = false;
      } else {
        this.isDoneFetchingAllMessages = true;
        this.isScrollTopLoading = false;
      }
    },
    selectedMessageId() {
      this.isDoneFetchingAllMessages = false;
      this.isScrollTopLoading = false;
      this.newChatMessage = false;
    },
    newMessage() {
      if (!this.newMessage?.sender?.is_owner) {
        if (!(this.scrollChatScrollTop >= -100)) {
          this.newChatMessage = true;
          this.newMessageCount++;
        }
      }
    },
  },
};
</script>

<style scoped>
.new-message-indicator {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  /* width: 118px; */
  height: 30px;
  margin: auto;
  padding: auto;
  border-radius: 15px;
  padding: 5px 10px;
  margin-bottom: 15vh;
  align-content: center;
  align-items: center;
  align-self: center;
  background: var(--el-app-primary);
  font-size: 0.9em;
  text-align: center;
  z-index: 5;
}

.new-message-indicator-text {
  color: white;
  font-size: 0.9em;
}

.select-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2rem;
}

#select-chat-image {
  height: 50%;
  width: 50%;
}

.chat-container {
  width: 100%;
  height: 110%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.78em;
  font-weight: 450;
  /* add border right color */
  border-right: 1.5px solid #c2bdbd;
}

#chat-messages-container {
  width: 90%;
  height: 70%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  max-height: 75%;
  overflow-y: auto;
}

#chat-input-container {
  width: 90%;
  height: 50px;
  display: flex;
  flex-direction: row;
  gap: 7px;
  position: sticky;
  /* margin: auto !important; */
  /* margin-top: 50px !important; */
  /* margin-top: 400px; */
}

#chat-input {
  /* width: 200px; */
  height: 35px;
  margin-top: 18px;
  /* add rounded corners to the input */
}

.chat-input-text {
  height: 38px !important;
  border: none;
  margin-top: 20px;
  border-radius: 50%;
  border: 0px 10px solid #a19d9d;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.receiver-position {
  /* background-color: RGB(227, 227, 227); */
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  width: 60%;
}

.clickable {
  cursor: pointer;
}

.receiver-bg-color {
  border-radius: 10px;
  background-color: #e8e8e8;
}

.sender-position {
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-end;
  width: 60%;
}

.sender-bg-color {
  border-radius: 10px;
  background-color: #ecf3fe;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  width: 80%;
  /* min-height: 650px; */
  min-height: 50vh;
  border-width: 5px;
  border-top-color: #a19d9d;
  /* box-shadow: 0px 0px 2px #a19d9d; */
}

.messages-available {
  display: flex;
  flex-direction: column-reverse;
}

.show {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.message-container {
  display: flex;
  flex-direction: row;
}

.delete-btn {
  cursor: pointer;
  color: red;
  padding-top: 15px;
  font-size: 15px;
  font-weight: bold;
}

.chat-message-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chat-message-content-text {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-self: flex-start;
  flex-wrap: wrap;
  text-align: left;
  padding: 16px;
}

.position-start {
  align-items: flex-start;
  align-content: flex-start;
  align-self: flex-start;
}

.position-end {
  align-items: flex-end;
  align-content: flex-end;
  align-self: flex-end;
}

.sender-first-name {
  /* font-weight: bold; */
  position: relative;
  top: -5px;
  font-size: 11px;
  font-weight: 600;
  font-style: italic;
  color: var(--el-app-primary);
}

/* round button */
#chat-send-button {
  display: flex;
  width: 35px;
  height: 37px;
  color: white;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 14px;
}

.send-input-button {
  border-radius: 50%;
  width: 44px !important;
  height: 40px !important;
  border: none;
  border-width: 5px;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.chat-message-content-date {
  font-size: 11px;
  color: #131414;
  font-weight: 500;
  margin-left: 10px;
}

.main-container {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.chat-header {
  width: 100%;
  text-align: center;
  margin-top: 18px;
  font-weight: 600;
  position: sticky;
}

input[type="text"] {
  border-radius: 20px !important;
}

#show-messages-button {
  font-size: 25px;
  display: none;
}

::-webkit-scrollbar {
  width: 2px;
}

.icon_container {
  font-size: 14px;
}

.sent-icon-color {
  color: rgb(24, 150, 24);
}

.date-title {
  color: rgba(0, 0, 0, 0.6);
}

.error-color {
  color: rgb(243, 39, 39);
}

.resend-message {
  display: flex;
  gap: 8px;
  font-size: 11px;
  color: rgb(0, 0, 0, 0.6);
  cursor: pointer;
  align-content: flex-start;
}

#loaded-chats {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 13px;
  padding-top: 15px;
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.5);
  gap: 4px;
}

.deleting-color {
  background-color: #f7dfdf;
}

.show-on-mobile {
  display: none;
}

.remove-on-mobile {
  display: block;
}

/* media queries for mobile and tablets */
@media (max-width: 768px) and (max-width: 1024px) {
  .messages-available {
    width: 100%;
  }
  .new-message-indicator {
    margin-bottom: 10vh;
  }
  .show-on-mobile {
    display: block;
  }

  .remove-on-mobile {
    display: none;
  }
  #show-messages-button {
    display: block;
    margin: 4px;
    /* margin-left: 220px; */
    align-self: flex-end;
  }

  .chat-header {
    width: 100%;
    text-align: center;
    margin-top: 18px;
  }

  .chat-input {
    margin-bottom: 10px;
  }

  .receiver-position,
  .sender-position {
    width: 90%;
  }

  #chat-input-container {
    width: 80%;
    margin: 0;
  }

  .chat-container {
    height: 110.5%;
    width: 100%;
  }

  #chat-input-container {
    width: 80%;
    margin: 0;
    display: flex;
    position: fixed;
    flex-direction: row;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: space-between;
    gap: 7px;
    bottom: 1.5vh;
  }

  .chat-container {
    height: 106.5%;
  }
  #chat-messages-container {
    height: 75%;
    padding-bottom: 5vh;
  }
}
</style>