import { nextTick } from "vue";
import store from "@/store";
import { RequestPayload } from "@/interfaces/request-payload";
import { Loading } from "element-ui";
import axios from "axios";
import {
  useShowErrorMessage,
  useShowSuccessMessage,
  useShowWarningMessage,
} from "@/composables/use-notifications";

export default async <T>(
  requestData: RequestPayload
): Promise<T | undefined> => {
  const baseUrl =
    process.env.VUE_APP_API_LOCAL_URL || process.env.VUE_APP_API_BASE_URL;

  let loadingIndicator;
  try {
    // IMPORTANT!!, before making the HTTP Call always first wait for the latest DOM updates otherwise this causes weird issues on reload
    await nextTick();
    if (requestData.loadingProperty) {
      requestData.loadingProperty.value = true;
    }

    if (requestData.errorLoadingProperty) {
      requestData.errorLoadingProperty.value = false;
    }

    if (requestData.showFullScreenLoader) {
      loadingIndicator = Loading.service({
        lock: true,
        text: requestData.fullScreenLoaderMessage || "Please wait...",
        background: "rgba(0, 0, 0, 0.7)",
      });
    }

    const { data } = await axios(`${baseUrl}/${requestData.url}`, {
      method: requestData.method,
      headers: {
        Authorization: store?.state?.accessToken
          ? `Bearer ${store.state.accessToken}`
          : "",
      },
      data: requestData.body,
    });

    if (requestData.isSilent) {
      return data as T;
    }

    if (requestData.showSuccessMessage) {
      useShowSuccessMessage(
        "Success",
        (data as any).message,
        requestData.persistSuccessNotification
      );
    }
    return data as T;
    // return data;
  } catch (err) {
    if (requestData.errorLoadingProperty) {
      requestData.errorLoadingProperty.value = true;
    }

    const error = err as any;

    if (requestData.isSilent) {
      return error?.response?.data;
    }

    if (error.message == "Network Error") {
      useShowWarningMessage(
        "Connection failed",
        "A network error occurred, please try again."
      );
      return;
    }

    if (error.response) {
      // If the error response is in the 400 range, we will show a warning message
      if (error.response.status >= 400 && error.response.status < 500) {
        useShowWarningMessage("Not Allowed", error.response.data.message);

        // If status code is 401, we will redirect to the login page
        // if (error.value.statusCode === 401) {
        //     useLogoutUser();
        //     return;
        // }

        return error.response.data;
      }

      // If the error response is in the 500 range, we will show an error message
      // else if (error.response.status >= 500) {
      //     useShowErrorMessage('Error', "An unexpected error occurred.")
      //     return error.response.data;
      // }
    }

    // else
    // showErrorMessage('Error', "Unexpected error occurred.")
    useShowErrorMessage("Error", "An unexpected error occurred.");
    return error.response.data;
  } finally {
    if (requestData.loadingProperty) {
      requestData.loadingProperty.value = false;
    }

    loadingIndicator?.close();
  }
};
