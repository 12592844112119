<template>
  <div class="container-fluid px-0 h-100" style="text-align: left">
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Faculties', 'Departments']" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-12 text-left">
              <div
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                "
              >
                <strong>Departments in </strong>
                <div>
                  {{ facultyName ? facultyName : "..." }}
                </div>
              </div>
            </div>
          </div>

          <div class="search_add_section">
            <div class="">
              <input
                type="text"
                v-model.trim="filters[0].value"
                placeholder="Search by Title"
                class="search_by_input"
                spellcheck="off"
              />
            </div>

            <el-button
              type="primary"
              :disabled="
                !$store.getters.canFacultiesToCourseUnitsCRUD ||
                !$store.getters.canFacultiesToCourseUnitsCRUDOnly
              "
              class="z-depth-0"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
              @click="showAddDepartmentDialog"
              >Add A Department</el-button
            >
          </div>

          <hr />

          <div v-loading="deleting" class="table-responsive">
            <data-tables
              :data="departments"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >
              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getDepartments"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Departments Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Departments</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Department Title"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.department_name }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="HODs"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
              >
                <template slot-scope="scope">
                  <div
                    v-for="hod of scope.row.department_heads"
                    :key="hod.staff_id"
                  >
                    {{ `${hod.first_name} ${hod.last_name}` }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="View Programmes"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="180px"
              >
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    @click="goToCourses(scope.row.department_id)"
                    round
                  >
                    {{ scope.row.courses }}
                    <span>{{
                      scope.row.courses == 1 ? "Programme" : "Programmes"
                    }}</span>
                  </el-button>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="150px"
              >
                <template slot-scope="scope">
                  <el-row
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <el-tooltip content="Edit" placement="top">
                      <el-button
                        type="primary"
                        class="mr-2"
                        :disabled="
                          !$store.getters.canFacultiesToCourseUnitsCRUD ||
                          !$store.getters.canFacultiesToCourseUnitsCRUDOnly
                        "
                        @click="
                          showEditDepartmentDialog(
                            scope.row.department_id,
                            scope.row.department_name,
                            scope.row.short_name
                          )
                        "
                        icon="el-icon-edit"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip content="Delete" placement="top">
                      <el-button
                        type="danger"
                        :disabled="true"
                        @click="deleteDepartment(scope.row.department_id)"
                        icon="el-icon-delete"
                        circle
                      ></el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>
            </data-tables>
          </div>
        </div>
      </section>

      <EditDepartmentDialog
        :is-visible="editDepartmentModal"
        :department-id="departmentId"
        :department-name="departmentName"
        :short-name="departmentShortName"
        @on-close="closeEditDepartmentDialog"
        @on-refresh="getDepartments"
      />

      <AddDepartmentDialog
        :is-visible="addDepartmentModal"
        @on-close="closeAddDepartmentDialog"
        @on-refresh="getDepartments"
      />
    </article>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbComponent from "../components/bread-crumb-component";
import AddDepartmentDialog from "../components/dialogs/add-department-dialog.vue";
import EditDepartmentDialog from "@/components/dialogs/edit-department-dialog.vue";

export default {
  components: {
    ScaleOut,
    BreadCrumbComponent,
    AddDepartmentDialog,
    EditDepartmentDialog,
  },
  mixins: [validationMixin],

  data() {
    return {
      addDepartmentModal: false,
      editDepartmentModal: false,
      departmentId: "",
      departmentName: "",
      departmentShortName: "",
      loading: false,
      loadingError: false,
      submiting: false,
      deleting: false,
      responseData: "",

      department_name: "",
      // short_name: "",
      // short_name_edit: "",
      department_name_edit: "",
      facultyName: this.$route.params.facultyName,
      departments: [],
      fid: this.$route.params.fId, //this is the faculty id from the browser

      tableProps: {
        border: true,
        stripe: true,
      },
      filters: [
        {
          prop: "department_name",
          value: "",
        },
      ],

      faculties_cUnits_crud: ["AR", "VC", "SUPER_ADMIN"],
    };
  },

  computed: {
    departmentNameError() {
      if (!this.$v.department_name.required) {
        return "*Department title is required";
      }
      return "";
    },

    // shortNameError() {
    //   if (!this.$v.short_name.required) {
    //     return "*Department code is required";
    //   }
    //   return "";
    // },

    departmentNameEditError() {
      if (!this.$v.department_name_edit.required) {
        return "*Department title is required";
      }
      return "";
    },

    // shortNameEditError() {
    //   if (!this.$v.short_name_edit.required) {
    //     return "*Department code is required";
    //   }
    //   return "";
    // },

    createdBy() {
      return (creator) => {
        if (!creator) return "N/A";
        return `${creator.first_name} ${creator.last_name}`;
      };
    },
  },

  validations: {
    department_name: {
      required,
    },
    // short_name: {
    //   required
    // },
    department_name_edit: {
      required,
    },
    // short_name_edit: {
    //   required
    // }
  },

  mounted() {
    this.getDepartments();
  },

  methods: {
    getDepartments23() {
      this.loading = true;
      this.loadingError = false;
      this.$http
        .get(`departments/fetch-many/${this.fid}`)
        .then(
          (response) => {
            if (
              response.data.success &&
              response.data.message == "FACULTY_DEPARTMENTS_FETCHED"
            ) {
              this.departments = response.data.departments;
              this.facultyName = response.data.faculty_name;
            } else {
              this.loadingError = true;
              this.showFailedMessage(
                "Fetch Failed",
                "An unexpected error occurred. Please try again"
              );
            }
          },
          () => {
            this.loadingError = true;
            this.showFailedMessage(
              "Fetch Failed",
              "An unexpected error occurred. Please try again"
            );
          }
        )
        .finally(() => (this.loading = false));
    },

    async getDepartments() {
      try {
        //Show Loading state
        this.loading = true;
        this.loadingError = false;

        let response = await this.$http.get(
          `departments/fetch-many/${this.fid}`
        );
        if (
          response.data.success &&
          response.data.message == "FACULTY_DEPARTMENTS_FETCHED"
        ) {
          this.departments = response.data.departments;
          this.facultyName = response.data.faculty_name;
        } else {
          this.loadingError = true;
          //Report the Error to an error reporting service
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: response.data,
            request: response,
          });

          //Throw this as an error
          throw "UNEXPECTED_RESPONSE_RECEIVED";
        }
      } catch (error) {
        //Handle Network Errors
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        //If the API returns other status codes besides 2xx, Handle these responses
        if (error.response) {
          this.loadingError = true;
          this.showFailedMessage(
            "Fetch Failed",
            "An unexpected error occurred. Please try again"
          );
        }

        //TODO: Handle default callback
      } finally {
        //Hide Loading state
        this.loading = false;
      }
    },

    async deleteDepartment(id) {
      return; // eslint-disable-line no-unreachable
      // eslint-disable-next-line no-unreachable
      this.$confirm(
        // eslint-disable-line no-unreachable
        "This will permanently delete this Programme. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            this.deleting = true;
            let request = await this.$http.delete(
              `departments/delete-one/${id}`
            );
            if (request.data.success) {
              this.getDepartments();
              this.showSuccessMessage(
                "Success",
                "Department deleted successfully!"
              );
            } else {
              this.$rollbar.warning(
                "ADMIN FRONT END: Unexpected API response while getting server response",
                request.data,
                request
              );
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              this.loadingError = true;
              return this.showFailedMessage(
                "Connection failed",
                "A network error occurred, please try again."
              );
            }

            this.showFailedMessage(
              "Unable to Delete",
              "Unable to delete the Department, Please try again"
            );
          } finally {
            this.deleting = false;
          }
        })
        .catch(() => {});
    },

    goToCourses(dId) {
      let departmentName = this.departments.find((department) => {
        return department.department_id == dId;
      }).department_name;

      this.$router.push({
        name: "DepartmentCourses",
        params: {
          dId,
          departmentName,
        },
      });
    },

    showAddDepartmentDialog() {
      this.addDepartmentModal = true;
    },

    closeAddDepartmentDialog() {
      this.addDepartmentModal = false;
    },

    showEditDepartmentDialog(id, departmentName, departmentShortName) {
      this.departmentName = departmentName;
      this.departmentId = id;
      this.departmentShortName = departmentShortName;
      this.editDepartmentModal = true;
    },

    closeEditDepartmentDialog() {
      this.editDepartmentModal = false;
    },
  },
};
</script>

<style scoped>
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_by_input {
  width: 400px !important;
}
/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.department_dialogs .el-dialog {
  width: 60%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .department_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .department_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .department_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .department_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
