<template>
  <div>
    <el-button
      style="background-color: var(--el-app-primary); color: white; width: auto"
      class="z-depth-0 addbtn"
      @click="showAMultipleStaffModal = true"
      v-ripple="'rgba(255, 255, 255, 0.35)'"
    >Generate Report</el-button>

    <div>
      <el-dialog
        title="Generate Report"
        width="500px"
        :visible.sync="showAMultipleStaffModal"
        :destroy-on-close="true"
        :before-close="handleClose"
      >
        <div v-loading="submiting">
          <div class="row">
            <div
              v-if="lecturerName"
              class="col-12 mb-3"
            >
              Lecture Report for {{lecturerName}}
            </div>
            <div class="col-6">
              <div class="block">
                <div class="demonstration pb-2">Select Month </div>
                <el-date-picker
                  v-model="monthAndYear"
                  :format="'MMMM yyyy'"
                  :value-format="'yyyy-MM-dd'"
                  type="month"
                  placeholder="Pick a month"
                >
                </el-date-picker>
              </div>
            </div>
            <div
              class="col-6 align-items-end"
              style="display:flex"
            >
              <el-button
                style="background-color: var(--el-app-primary); color: white; width: auto; height: 2.6rem"
                class="z-depth-0 addbtn"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                :disabled="!monthAndYear"
                @click="onGenerateReport"
              >Generate Report</el-button>
            </div>
          </div>

        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submiting: false,
      showAMultipleStaffModal: false,
      monthAndYear: null,
    };
  },

  props: {
    lecturerId: {
      required: true,
      type: String,
    },
    lecturerName: {
      required: false,
      type: String,
    },
  },
  methods: {
    async onGenerateReport() {
      if (!this.monthAndYear) return;
      try {
        this.submiting = true;
        let request = await this.$http.post(
          `/live-lecture/generate-monthly-report-for-lecturer/${this.lecturerId}`,
          {
            monthAndYear: this.monthAndYear,
          }
        );

        if (
          request.data.success &&
          request.data.message == "LECTURES_REPORT_GENERATED"
        ) {
          this.showSuccessMessage(
            "Report Generated",
            "Report Generated Successfully"
          );

          const link = document.createElement("a");
          link.href = request.data.link;
          // link.download = "Question Paper";
          link.click();
        } else if (request.data.message == "NO_LIVE_LECTURES_FOUND") {
          return this.showWarningMessage(
            "No Records",
            "There are no Live Lecture Records to generate Reports for."
          );
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        } else {
          this.showFailedMessage(
            "Unexpected error occurred",
            "Unable to Generate Report now. Please try again later."
          );
        }
      } finally {
        this.submiting = false;
      }
    },
  },
};
</script>

<style>
</style>