<template>
  <div class="row w-100 mx-0">
    <div class="row w-100 mx-0">
      <div
        class="col-12"
        style="
          display: flex;
          justify-content: space-between;
          align-items: start;
        "
      >
        <span
          v-if="examination.title"
          class="pb-4"
          style="font-weight: 600; text-transform: capitalize; font-size: 1.2em"
        >
          {{ examination.title.toLowerCase() }}
        </span>

        <el-button
          size="small"
          type="success"
          :disabled="!fileExtension"
          @click="downloadFile"
        >Download {{ documentTypeFormatted }}
          <i
            style="margin-left: 10px"
            class="fas fa-download"
          ></i>
        </el-button>
      </div>
    </div>

    <div
      v-if="!fileExtension"
      class="col-12"
      style="margin-top: 80px"
    >
      <img
        src="../../assets/img/no-content.png"
        alt=""
        style="height: 100px"
      />
      <div
        class="pt-3"
        style="font-weight: 500; font-size: 0.9em; color: rgba(0, 0, 0, 0.45)"
      >
        {{ documentTypeFormatted }} not submitted
      </div>
    </div>

    <!-- DOCUMENT examinationS -->
    <div
      v-else-if="fileExtension == 'pdf'"
      class="row w-100 mx-0"
    >
      <div
        v-show="!isLoading"
        class="row w-100 mx-0"
      >
        <div class="col-12 text-right">
          <span
            class="pr-2"
            style="font-weight: 400"
          >
            Page <strong>{{ activePage }}</strong> of
            {{ totalPages ? totalPages : "N/A" }}
          </span>
          <div style="display: inline-flex">
            <div
              @click="onPreviousPage"
              class="direction_arrows"
            >
              <i class="fas fa-long-arrow-alt-left"></i>
            </div>
            <div
              @click="onNextPage"
              class="direction_arrows"
            >
              <i class="fas fa-long-arrow-alt-right"></i>
            </div>
          </div>
        </div>
        <div class="col-12">
          <pdf
            :src="fileURL"
            :page="activePage"
            @num-pages="onPageNumberObtained"
            @progress="onProgress"
            @loaded="onLoaded"
            @error="onPDfError"
          >
          </pdf>
        </div>
        <div class="col-12 text-right">
          <span
            class="pr-5"
            style="font-weight: 400"
          >Page <strong>{{ activePage }}</strong> of
            {{ totalPages ? totalPages : "N/A" }}
          </span>
          <div
            class="pt-3"
            style="display: inline-flex"
          >
            <div
              @click="onPreviousPage"
              class="direction_arrows"
            >
              <i class="fas fa-long-arrow-alt-left"></i>
            </div>
            <div
              @click="onNextPage"
              class="direction_arrows"
            >
              <i class="fas fa-long-arrow-alt-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="isLoading"
        class="col-12 text-center"
        style="margin-top: 130px"
      >
        <ScaleOut
          :background="'#1F4EA1'"
          class="mx-auto"
        />
        <div style="font-size: 0.9em; font-weight: 500">
          {{ downloadProgress }}% Loading...
        </div>
      </div>
    </div>

    <div
      class="row w-100 mx-0"
      v-else-if="fileExtension == 'zip'"
    >
      <div class="col-12">
        <div
          class="pt-5 pb-3"
          style="font-size: .9em"
        >Download the <b>ZIP file</b> to view the contents</div>
        <img
          src="@/assets/img/zip.png"
          alt=""
          height="120px"
        />
      </div>
    </div>

    <!-- OTHER SUPPORTED DOCUMENT VIEWER LONG AS IT'S ONE OF .DOC, .DOCX, .XLS, .XLSX, .PPT, .PPTX, .ODT, .ODS, .ODP, .PDF, .TXT -->
    <div
      v-else
      class="row w-100 mx-0"
    >
      <div class="col-12 text-left">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            id="document_viewer_live"
            :src="`https://view.officeapps.live.com/op/embed.aspx?src=${fileURL}`"
            class="embed-responsive-item"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import ScaleOut from "@/components/scale-out-component.vue";
export default {
  components: { pdf, ScaleOut },
  data() {
    return {
      activePage: 1,
      progress: 0,
      totalPages: "N/A",
      isLoading: false,
      onPDfError: false,
      downloadProgress: "",
    };
  },
  computed: {
    documentTypeFormatted() {
      return this.type == "questionPaper" ? "Question Paper" : "Answer Sheet";
    },

    fileExtension() {
      if (this.type == "questionPaper") {
        return (
          this.examination.question_paper_path_url?.substring(
            this.examination.question_paper_path_url.lastIndexOf(".") + 1
          ) || ""
        );
      }

      return (
        this.examination.answer_sheet_path_url?.substring(
          this.examination.answer_sheet_path_url.lastIndexOf(".") + 1
        ) || ""
      );
    },

    fileURL() {
      return this.type == "questionPaper"
        ? this.examination.question_paper_path_url
        : this.examination.answer_sheet_path_url;
    },
  },
  props: {
    examination: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  methods: {
    onLoaded() {
      this.isLoading = false;
    },

    async downloadFile() {
      // window.open(this.fileURL)

      const link = document.createElement("a");
      link.href = this.fileURL;
      link.download = "Question Paper";
      link.click();

      //   this.$http
      //     .get(this.fileURL, { responseType: "blob", withCredentials: false })
      //     .then((response) => {
      //       const blob = new Blob(
      //         [response.data] /*  { type: 'application/pdf' } */
      //       );
      //       const link = document.createElement("a");
      //       link.href = URL.createObjectURL(blob);
      //       link.download = "Question Paper";
      //       link.click();
      //       URL.revokeObjectURL(link.href);
      //     })
      //     .catch(console.error);

      //   const ext = answer_path_url.substring(
      //     answer_path_url.lastIndexOf(".") + 1
      //   );
      //   this.submiting = true;
      //   this.$http
      //     .get(`examination/download-answer-sheet/${answer_path_url}`, {
      //       responseType: "blob",
      //     })
      //     .then((response) => {
      //       const url = URL.createObjectURL(new Blob([response.data]));
      //       const link = document.createElement("a");
      //       link.href = url;
      //       link.setAttribute(
      //         "download",
      //         `Question-paper-${new Date().toLocaleDateString()}.${ext}`
      //       );
      //       document.body.appendChild(link);
      //       link.click();
      //     })
      //     .finally(() => {
      //       this.submiting = false;
      //     });
    },

    onProgress(progress) {
      if (!progress) return;
      this.progress = progress;
    },
    onNextPage() {
      if (this.activePage == this.totalPages) return;
      ++this.activePage;
      window.scrollTo(0, 0);
    },
    onPreviousPage() {
      if (this.activePage == 1) return;
      --this.activePage;
      window.scrollTo(0, 0);
    },
    onPageNumberObtained(pageNumber) {
      this.totalPages = pageNumber;
    },
  },
};
</script>

<style>
</style>