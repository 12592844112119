export default function (num, shortName = false) {
    if (num < 0 || num > 6) {
        return "N/A"
    }
    switch (num) {
        case 0:
            return shortName ? "Sun" : "Sunday";
        case 1:
            return shortName ? "Mon" : "Monday";
        case 2:
            return shortName ? "Tue" : "Tuesday";
        case 3:
            return shortName ? "Wed" : "Wednesday";
        case 4:
            return shortName ? "Thur" : "Thursday";
        case 5:
            return shortName ? "Fri" : "Friday";
        case 6:
            return shortName ? "Sat" : "Saturday";

        default:
            return "N/A"
    }
}