<template>
  <div>
    <article>
      <section>
        <div class="container">
          <div class="students_dialogs text-left">
            <el-dialog
              title="Student Intake"
              :visible.sync="intakeBatchModal"
              :before-close="handleClose"
              :destroy-on-close="true"
              width="35%"
            >
              <div v-loading="submitting">
                <div v-if="!studentEditStatus">
                  <div>
                    <p>
                      <span style="font-weight: 700">Course:</span>
                      {{ courseTitle }}
                    </p>
                    <p>
                      <span style="font-weight: 700">Year of Intake:</span>
                      {{ courseIntakeBatch.year }}<br />
                    </p>
                    <p>
                      <span style="font-weight: 700">Month of Intake:</span>
                      {{ toMonthName(courseIntakeBatch.month) }}
                    </p>
                    <p>
                      <span style="font-weight: 700">Cohort:</span>
                      {{ courseIntakeBatch.cohort }}
                    </p>
                  </div>
                </div>

                <div v-if="studentEditStatus">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="mb-3">Choose Intake</label>
                        <el-select
                          v-model="searched_intake_value"
                          :disabled="isBusy"
                          filterable
                          remote
                          placeholder="Enter intake"
                          :remote-method="onSearchModules"
                          :loading="isSearching"
                        >
                          <el-option
                            v-for="item in courseUnitsWithCourseIntakeBatches"
                            :key="item.course_intake_batch_id"
                            :label="item.intake"
                            :value="item.course_intake_batch_id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <!-- @change="handleCourseUnitsSelectionChange" -->
                        <el-checkbox
                          v-model="forceUpdateRegNo"
                          id="forceUpdateRegNo"
                        >
                          Force update Registration No.
                        </el-checkbox>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <el-button
                    v-if="!studentEditStatus"
                    type="primary"
                    @click="showEditSection"
                    icon="el-icon-edit"
                    >Change Intake</el-button
                  >
                  <div v-if="studentEditStatus">
                    <el-button type="primary" @click="submitBatchChanges"
                      >Save Changes</el-button
                    >
                    <el-button
                      type="success"
                      @click="showStatusSection"
                      icon="el-icon-arrow-left"
                    >
                      Back</el-button
                    >
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import getMonthName from "../../helpers/getMonthName";
export default {
  data() {
    return {
      intakeBatchModal: false,
      courseTitle: "",
      courseIntakeBatch: "",
      courseIntakeBatches: [],
      isFetching: false,
      isLoadingError: false,
      loading: true,
      loadingError: false,
      submitting: false,
      deleting: false,
      courseIntakeBatchChoice: "",
      studentEditStatus: false,
      courseUnitsWithCourseIntakeBatches: [],

      searched_intake_value: "",

      isBusy: false,
      isSearching: false,
      forceUpdateRegNo: false,
    };
  },

  props: {
    studentId: {
      type: String,
      required: true,
    },
    showStudentIntakeDialog: {
      type: Boolean,
      required: true,
    },
  },

  watch: {
    showStudentIntakeDialog() {
      if (this.showStudentIntakeDialog == true) {
        this.intakeBatchModal = true;
        this.showModal();
      } else {
        this.intakeBatchModal = false;
      }
    },
  },

  methods: {
    async onSearchModules(query) {
      if (query.length >= 3) {
        this.isSearching = true;
        try {
          let request = await this.$http.get(
            `course-intake-batches/search?search_term=${query}`
          );
          if (
            request.data.success &&
            request.data.message == "COURSE INTAKE BATCHES FETCHED SUCCESSFULLY"
          ) {
            this.courseUnitsWithCourseIntakeBatches = request.data.intakes;
          } else {
            this.$rollbar.warning(
              "ADMIN FRONT END: Unexpected API response while getting server response",
              request.data,
              request
            );
            throw "UNEXPECTED_RESPONSE";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          // console.log(error);
          this.showFailedMessage(
            "Loding Failed",
            "Unable to search Modules now, Please try again later"
          );
        } finally {
          this.isSearching = false;
        }
      }
    },

    showEditSection() {
      this.studentEditStatus = true;
    },

    showStatusSection() {
      this.studentEditStatus = false;
    },

    handleClose() {
      this.forceUpdateRegNo = false;
      this.searched_intake_value = "";
      this.courseUnitsWithCourseIntakeBatches = [];

      this.intakeBatchModal = false;
      this.$emit("closeStudentIntakeDialog");
    },

    toMonthName(monthNum) {
      return getMonthName(monthNum, true);
    },
    async showModal() {
      try {
        this.submitting = true;
        await Promise.all([
          this.getIntakeBatch(),
          this.getCourseIntakeBatches(),
        ]);
      } catch (error) {
        this.submitting = true;
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again"
          );
        }
        this.showFailedMessage(
          "Unable to retrieve Student Intake",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.submitting = false;
      }
    },

    async getIntakeBatch() {
      // eslint-disable-next-line no-useless-catch
      try {
        this.submitting = true;
        let request = await this.$http.get(
          `students/course-intake-batch/${this.studentId}`
        );
        if (
          request.data.success &&
          request.data.message == "STUDENT_BATCH_RETREIVED_SUCCESSFULLY"
        ) {
          if (request.data.student.course_intake_batch == null) {
            this.studentEditStatus = true;
            return this.showWarningMessage(
              "Student has no Intake Intake",
              "Please assign student a Intake"
            );
          } else {
            this.studentEditStatus = false;
            this.courseIntakeBatch = request.data.student.course_intake_batch;
            this.courseTitle = this.courseIntakeBatch.course.title;
          }
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        throw error;
      }
    },

    async getCourseIntakeBatches() {
      // eslint-disable-next-line no-useless-catch
      try {
        this.submitting = true;
        let request = await this.$http.get(`course-intake-batches/structured`);
        if (
          request.data.success &&
          request.data.message == "COURSE_BATCHES_FETCHED_SUCCESSFULLY"
        ) {
          this.courseIntakeBatches = request.data.batches;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        throw error;
      }
    },

    async submitBatchChanges() {
      try {
        this.submitting = true;
        let request = await this.$http.patch(
          `students/update-intake/${this.studentId}?forceUpdateRegNo=${this.forceUpdateRegNo}`,
          { course_intake_batch_id: this.searched_intake_value }
        );
        if (
          request.data.success &&
          request.data.message == "STUDENT INTAKE UPDATED"
        ) {
          this.showSuccessMessage("Success", "Student Intake Intake Updated");

          this.intakeBatchModal = false;
          this.$emit("re-fresh");
          this.handleClose();
          this.studentEditStatus = false;
          this.submitting = false;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.submitting = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        //If the API returns other status codes besides 2xx, Handle these responses
        if (error.response) {
          if (error.response.data.message === "API MESSAGE HERE") {
            //TODO: Handle this response

            return;
          }
        } else {
          this.showFailedMessage(
            "Unable to change Intake",
            "An unexpected Error occurred, please try again"
          );
        }
        console.log(error);
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style scoped></style>

<style>
.el-autocomplete {
  width: 100%;
}
</style>
