var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.BaseDialog,{attrs:{"title":`Update Live Class for ${_setup.lecturerName}`,"dialog-visible":_vm.isVisible,"size":"medium"},on:{"close":_setup.onDialogClose,"open":_setup.onOpen}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_setup.loading),expression:"loading"}]},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-12 mb-4 choose-modules"},[_c('label',[_vm._v("Choose Modules")]),_c('el-select',{staticClass:"d-block",attrs:{"placeholder":"Select","multiple":"","filterable":""},model:{value:(_setup.chosenModules),callback:function ($$v) {_setup.chosenModules=$$v},expression:"chosenModules"}},_vm._l((_setup.courseUnitsWithBatches),function(courseUnit,index){return _c('el-option',{key:index,attrs:{"label":`${courseUnit.course_unit.course_unit_code} ${
              courseUnit.course_unit.title
            } in ${
              courseUnit.course_intake_batch.course.course_code
            } ${_setup.getMonthName(
              courseUnit.course_intake_batch.month
            ).toUpperCase()}/ ${courseUnit.course_intake_batch.year} ${
              courseUnit.course_intake_batch.cohort || ''
            }`,"value":index}})}),1)],1),_c('div',{staticClass:"col-md-6 mb-2"},[_c('label',[_vm._v("Session")]),_c('el-select',{staticClass:"d-block",attrs:{"multiple":"","placeholder":"Select"},model:{value:(_setup.selectedLectureSessions),callback:function ($$v) {_setup.selectedLectureSessions=$$v},expression:"selectedLectureSessions"}},_vm._l((_setup.sessions),function(session){return _c('el-option',{key:session.value,attrs:{"label":session.label,"value":session.value}})}),1)],1),_c('div',{staticClass:"col-md-6 mb-2 intake-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Intake groups")]),_c('br'),_c('el-select',{staticClass:"d-block",attrs:{"placeholder":"Search By Course Code","multiple":"","filterable":"","remote":"","remote-method":_setup.onSearchIntakeGroups,"loading":_setup.isSearching},model:{value:(_setup.chosenIntakeGroups),callback:function ($$v) {_setup.chosenIntakeGroups=$$v},expression:"chosenIntakeGroups"}},_vm._l((_setup.intakeGRoupSearchResults),function(group){return _c('el-option',{key:group.course_intake_batch_group_id,attrs:{"label":group.label,"value":group.course_intake_batch_group_id}})}),1)],1)]),_c('span',{staticClass:"dialog-footer d-flex justify-content-end"},[_c('el-button',{staticClass:"w-auto",class:{
          'not-allowed':
            !_setup.selectedLectureSessions.length || !_setup.chosenModules.length,
        },attrs:{"type":"primary","disabled":!_setup.selectedLectureSessions.length || !_setup.chosenModules.length},on:{"click":_setup.onUpdateLiveLecture}},[_vm._v("Update Class")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }