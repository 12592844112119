<template>
  <div>
    <article>
      <section>
        <div class="container">
          <div class="batch_dialogs">
            <el-dialog
              title="Grading Sheet"
              :visible="isVisible"
              @open="showModel"
              :before-close="closeDialog"
            >
              <div v-loading="submiting">
                <el-table
                  :data="gradingSheet"
                  border
                  stripe
                  style="width: 100%"
                  id="o-level-grading"
                  :key="tableKey"
                >
                  <el-table-column
                    label=""
                    width="40"
                  >
                    <template #default="scope">
                      <el-checkbox
                        v-model="scope.row.is_checked"
                        @change="handleGradeSelectionChange"
                      ></el-checkbox>
                    </template>
                  </el-table-column>
                  <el-table-column label="Grade">
                    <template #default="scope">
                      {{ scope.row.grade }}
                    </template>
                  </el-table-column>
                  <el-table-column label="Lower Limit">
                    <template #default="scope">
                      <input
                        type="text"
                        v-mask="amountMask"
                        v-model="scope.row.min_score"
                        placeholder="lower limit"
                        class="grade-input"
                        :disabled="!scope.row.is_checked"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column label="Upper Limit">
                    <template #default="scope">
                      <input
                        type="text"
                        v-mask="amountMask"
                        v-model="scope.row.max_score"
                        placeholder="upper limit"
                        class="grade-input"
                        :disabled="!scope.row.is_checked"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column label="Grade points">
                    <template #default="scope">
                      <input
                        type="text"
                        v-model="scope.row.grade_points"
                        placeholder="Grade points"
                        class="grade-input"
                        :disabled="!scope.row.is_checked"
                      />
                    </template>
                  </el-table-column>
                </el-table>

                <div class="form-group text-center">
                  <el-button
                    style="
                      background-color: var(--el-app-primary);
                      color: white;
                    "
                    v-ripple="'rgba(255, 255, 255, 0.35)'"
                    class="z-depth-0 mt-4"
                    v-on:click="saveGradingSheet"
                  >
                    save Grades
                  </el-button>
                </div>

                <div class="text-center">
                  <div class="text-center">
                    
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
export default {
  data() {
    return {
      // openDetailedGradingModal: false,
      loading: false,
      loadingError: false,
      submiting: false,
      gradingSystem: {},
      gradingSheet: [],
      errors: [],
      thisGradingSystemData: "",
      gradingSheetTable: [
        // create skeleton of the grading system
        {
          id: "o1",
          min_score: "",
          max_score: "",
          grade_points: 0,
          grade: "A",
          is_checked: true,
        },
        {
          id: "o2",
          min_score: "",
          max_score: "",
          grade_points: 0,
          grade: "B+",
          is_checked: true,
        },
        {
          id: "o3",
          min_score: "",
          max_score: "",
          grade_points: 0,
          grade: "B",
          is_checked: true,
        },
        {
          id: "o4",
          min_score: "",
          max_score: "",
          grade_points: 0,
          grade: "C+",
          is_checked: true,
        },
        {
          id: "o5",
          min_score: "",
          max_score: "",
          grade_points: 0,
          grade: "C",
          is_checked: true,
        },
        {
          id: "o6",
          min_score: "",
          max_score: "",
          grade_points: 0,
          grade: "D+",
          is_checked: true,
        },
        {
          id: "o7",
          min_score: "",
          max_score: "",
          grade_points: 0,
          grade: "D",
          is_checked: true,
        },
        {
          id: "o8",
          min_score: "",
          max_score: "",
          grade_points: 0,
          grade: "F",
          is_checked: true,
        },
      ],
      requiredGrades: [],
      tableKey: 0,
      amountMask: createNumberMask({
        prefix: "", //__ allow only positive numbers
        // prefix: /^\d*[1-9]\d*$/, //__ allow only positive numbers
        allowDecimal: true,
      }),
    };
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    gradingSystemToDetailId: {
      type: String,
      required: true,
    },
  },

  methods: {
    closeDialog() {
      this.$emit("close-dialog");
      ++this.tableKey;
    },
    handleGradeSelectionChange() {
      this.gradingSheet.forEach((grade) => {
        if (!grade.is_checked) {
          this.$set(grade, "min_score", "");
          this.$set(grade, "max_score", "");
          this.$set(grade, "grade_points", "");
        }
      });
    },
    validateMaxInputs() {
      let inputsAreValid = false;
      // filter out the checked grades
      this.requiredGrades = this.gradingSheet.filter((grade) => {
        if (grade.is_checked == true) {
          return grade;
        }
      });
      // for (let i = 0; i < this.gradingSheet.length; i++) {
      //   if (this.gradingSheet[i].min_score === "") {
      // validating the checked grades.
      for (let i = 0; i < this.requiredGrades.length; i++) {
        if (this.requiredGrades[i].min_score === "") {
          this.showWarningMessage(
            "Missing score",
            `Grade ${this.requiredGrades[i].grade} min score required`
          );
          this.submiting = false;
          inputsAreValid = false;
          break;
        } else if (this.requiredGrades[i].min_score === 0) {
          inputsAreValid = true;
        } else if (this.requiredGrades[i].max_score === "") {
          this.showWarningMessage(
            "Missing Score",
            `Grade ${this.requiredGrades[i].grade} max score required`
          );
          this.submiting = false;
          inputsAreValid = false;
          break;
        } else if (this.requiredGrades[i].remarks === "") {
          this.showWarningMessage(
            "Missing Grade points",
            `Grade ${this.requiredGrades[i].grade} Grade points required`
          );
          this.submiting = false;
          inputsAreValid = false;
          break;
        } else {
          inputsAreValid = true;
        }
      }
      return inputsAreValid;
    },

    async showModel() {
      this.submiting = true;
      try {
        await this.getGradingSheet();
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to get grading",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.submiting = false;
      }
    },

    async saveGradingSheet() {
      if (!this.validateMaxInputs()) return;
      try {
        this.submiting = true;
        let request = await this.$http.post(`examinations/grading-sheet/save`, {
          grading_system_id: this.gradingSystemToDetailId,
          grading_sheet: this.requiredGrades,
        });
        if (
          request.data.success &&
          request.data.message == "GRADING SHEET SAVED SUCCESSFULLY"
        ) {
          this.showSuccessMessage(
            "Success",
            "Grading sheet saved successfully"
          );
          this.closeDialog();
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again"
          );
        }
        if (
          error.response.data.message == "GRADING SHEET MULTIPLE GRADE AWARD"
        ) {
          this.showWarningMessage(
            "Multiple grades",
            `The Number ${error.response.data.data} belong to multiple Grades`
            // fetch [num] from api
          );
        } else if (
          error.response.data.message == "GRADING SHEET NUMBER NOT COVERED"
        ) {
          this.showWarningMessage(
            "Incomplete grading",
            `The Number ${error.response.data.data} does not belong to any Grading group`
            // fetch [num] from api
          );
        } else {
          this.showFailedMessage(
            "Unable to add grading sheet",
            "An unexpected error occurred, please try again"
          );
        }
      } finally {
        this.submiting = false;
      }
    },

    async getGradingSheet() {
      // eslint-disable-next-line no-useless-catch
      try {
        this.submiting = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `examinations/grading-sheet/by-grading-system-id/${this.gradingSystemToDetailId}`
        );
        if (request.data.success) {
          this.gradingSystem = request.data.grading_system;
          this.gradingSheet = this.gradingSystem.grading_sheet.map((grade) => {
            // add is_checked is true to available grades so that they are pre_checked
            return { ...grade, is_checked: true };
          });

          // if their are no grades, then provide grading skeleton (gradingSheet table)
          if (this.gradingSheet.length == 0) {
            this.gradingSheet = this.gradingSheetTable;
          } else {
            return this.gradingSheet;
          }
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>

<style scoped>
.grade-input {
  width: 100%;
  height: 36px !important;
}
input:disabled {
  background: #f5f7fa !important;
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
