const sort = (a, b, sortAttribute) => {
  return a[sortAttribute] > b[sortAttribute] ? -1 : 1;
};

//sorts in the reverse order
export const sortArray = (array, sortAttribute) => {
  return array.sort((a, b) => sort(a, b, sortAttribute));
};

export const sortArrayByDate = (array, sortAttribute) => {
  return array.sort((a, b) => {
    const dateA = new Date(a[sortAttribute]);
    const dateB = new Date(b[sortAttribute]);
    return dateB - dateA;
  });
};
