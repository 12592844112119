import store from "@/store";
import { AxiosResponse } from "axios";

export const compareAppVersion = (response: AxiosResponse<any>) => {
  if (
    !response ||
    !process.env.VUE_APP_CHECK_APP_VERSION_FROM_API ||
    process.env.VUE_APP_IS_STAGING
  )
    return;

  const appVersion = process.env.VUE_APP_VERSION;
  if (response.data?._v) {
    if (response.data?._v !== appVersion) {
      store.state.isAlreadyShowingCheckForUpdatesDialog = true;
    }
  }
};
