export enum RoleType {
  VC = "VC",
  AR = "AR",
  HOD = "HOD",
  SUPER_ADMIN = "SUPER_ADMIN",
  DEAN = "DEAN",
  QA = "QA",
  IT = "IT",
  LECTURER = "LECTURER",
  BURSAR = "BURSAR",
  HEAD_BURSAR = "HEAD_BURSAR",
  HR = "HR",
  EXAMS = "EXAMS",
  STUDENT_APPLICATIONS = "STUDENT_APPLICATIONS",
  DEAN_OF_STUDENTS = "DEAN_OF_STUDENTS",
  WEBSITE_ADMIN = "WEBSITE_ADMIN",
  NON_TEACHING_STAFF = "NON_TEACHING_STAFF",
  CAFETERIA_ADMIN = "CAFETERIA_ADMIN",
}
