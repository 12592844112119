<template>
  <div id="app">
    <AuthenticatingComponent v-if="$store.state.isAuthenticating" />
    <router-view v-else />
    <div class="staging_env_banner d-none d-lg-block" v-if="isStagingEnv">
      Staging Environment
    </div>
    <div class="app-version">{{ appVersion }}</div>
  </div>
</template>

<script>
import "firebase/firestore";
import "firebase/messaging";
import AuthenticatingComponent from "./components/authenticating.component.vue";
const isStagingEnv = process.env.VUE_APP_IS_STAGING;
const APP_VERSION = process.env.VUE_APP_VERSION;
const isDev =
  process.env.VUE_APP_IS_STAGING || process.env.NODE_ENV !== "production";

export default {
  data() {
    return {
      installingNotification: null,
      isStagingEnv,
      appVersion: isDev ? "Development" : `Version 3.${APP_VERSION}`,
    };
  },

  components: {
    AuthenticatingComponent,
  },

  computed: {
    permissionError() {
      return this.$store.state.permissionError;
    },

    isAlreadyShowingCheckForUpdatesDialog() {
      return this.$store.state.isAlreadyShowingCheckForUpdatesDialog;
    },
  },

  watch: {
    permissionError() {
      // alert(this.permissionError);
      this.showWarningMessage(
        "No Permissions",
        "You have no permission to do the required Operation"
      );
    },

    isAlreadyShowingCheckForUpdatesDialog() {
      this.$notify({
        title: "Check Updates",
        dangerouslyUseHTMLString: true,
        // iconClass: "fas fa-download",
        type: "info",
        showClose: false,
        onClick: () => {
          location.reload();
          return false;
        },
        message: `Your app may be outdated. Please click below to check for an update
                      <button
                          type="button"
                          class="mt-2 btn z-depth-0"
                          v-ripple="'rgba(255, 255, 255, 0.35)'"
                          style="
                            width: 100px;
                            text-transform: capitalize;
                            font-size: 13px;
                            height: 35px;
                            border-radius: 40px;
                            padding: 10px 10px;
                            background-color: var(--el-app-primary);
                          "
                        >
                          <span style="position: relative; bottom: 3px">Check</span>
                        </button>
                      `,
        duration: 0,
      });
    },
  },

  methods: {},

  created() {
    document.addEventListener(
      "visibilitychange",
      function () {
        document.dispatchEvent(
          new CustomEvent("appVisibilityChange", { detail: document.hidden })
        );
      },
      false
    );

    navigator.serviceWorker.addEventListener("message", (event) => {
      //If it is an update request, then update the app
      if (
        (event.data && event.data.title == "Check Update") ||
        (event.data.data && event.data.data.title == "Check Update")
      ) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            let serviceWorker = registrations.find(
              (worker) =>
                worker.active &&
                worker.active.scriptURL.includes("service-worker.js")
            );
            //If we found it, attempt to fetch updates
            if (serviceWorker) {
              serviceWorker.update();
            }
          });
      }
    });

    document.addEventListener(
      "swUpdateFound",
      () => {
        this.$notify({
          title: "We are Updating",
          // type: "info",
          iconClass: "el-icon-loading mt-2",
          showClose: false,
          message: "Fetching new update now...",
        });
      },
      { once: true }
    );

    document.addEventListener(
      "swUpdated",
      () => {
        this.$notify({
          title: "Update Available",
          dangerouslyUseHTMLString: true,
          // iconClass: "fas fa-download",
          type: "info",
          showClose: false,
          onClick: () => {
            location.reload();
            return false;
          },
          message: `There is an update available. Please Click below to refresh
                      <button
                          type="button"
                          class="mt-2 btn z-depth-0"
                          v-ripple="'rgba(255, 255, 255, 0.35)'"
                          style="
                            width: 100px;
                            text-transform: capitalize;
                            font-size: 13px;
                            height: 35px;
                            border-radius: 40px;
                            padding: 10px 10px;
                            background-color: var(--el-app-primary);
                          "
                        >
                          <span style="position: relative; bottom: 3px">Update</span>
                        </button>
                      `,
          duration: 0,
        });
      },
      { once: true }
    );
  },

  destroyed() {},
};
</script>

<style>
/* input.border-color-fix:focus:not(.input-error) {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
} */

/* Global override to remove the max-width as it was used on all pages and causes large paddings on the sides and a global replace on all pages
was very expensive to do */
.container {
  max-width: none !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.staging_env_banner {
  background-color: var(--vu-red) !important;
  color: white;
  padding: 5px 10px;
  z-index: 2000;
  font-size: 0.9em;
  border-radius: 5px;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

input:focus:not(.input-error) {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

.el-message-box__title {
  font-size: 17px;
  font-weight: 600;
  font-family: "Euclid Circular B";
}

.el-popper {
  font-family: "Euclid Circular B" !important;
}

.el-button--primary {
  color: #fff;
  background-color: var(--el-app-primary) !important;
  border-color: var(--el-app-primary) !important;
}

.el-message-box__content {
  color: #4d4e52;
  font-weight: 400;
  font-size: 13px;
  font-family: "Euclid Circular B";
}

.el-notification__content {
  text-align: left !important;
}

.el-notification.right {
  font-family: "Euclid Circular B" !important;
  z-index: 9999 !important;
}
.bread-crumb-item {
  font-size: 0.8em;
  font-weight: 500;
  color: #919191;
  cursor: pointer;
}

.bread-crumb-item:hover,
.bread-crumb-home:hover {
  color: #000 !important;
  transition: 1s;
}

#app {
  font-family: "Euclid Circular B";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
input.legacy-input {
  height: calc(1.55rem) !important;
  padding: 5px;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
input.legacy-input:disabled {
  background-color: rgb(248, 246, 246) !important;
}
input.legacy-input.input-error {
  border: 1px solid red !important;
  box-shadow: none !important;
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /*  animation-iteration-count:  infinite;  */
}
input.legacy-input:focus:not(.input-error) {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eeeeee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 10px;
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 10px;
  background: #164b70;
  border-radius: 10px;
}

.card {
  box-shadow: 0 2px 5px 2px rgb(0 0 0 / 0%), 0 2px 10px 0 rgb(0 0 0 / 5%) !important;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.app-version {
  position: fixed;
  bottom: 0px;
  right: 0px;
  font-size: 0.65em;
  color: rgba(0, 0, 0, 0.473);
  padding: 2px 8px;
  background-color: white;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}
</style>
