<template>
  <section class="container" v-loading="dataLoading">
    <div class="dashboard_inner_grid">
      <div>
        <div style="margin-bottom: 20px">
          <NoticeBoardDisplayComponent
            :notices="notices"
          ></NoticeBoardDisplayComponent>
        </div>

        <QuickStatisticsComponent
          :active_students_today="active_students_today"
          :student_count="student_count"
          :staff_count="staff_count"
        />

        <DashboardItemsSummary />
      </div>

      <div class="bg-white" style="border-radius: 3px">
        <CalendarComponent
          v-loading="isMonthCalendarLoading"
          :eventsDays="eventDays"
          :year="calendarYear"
          :month="calendarMonth"
          :dateToday="dateToday"
          @on-change-month="onChangeCalendarMonth"
        >
        </CalendarComponent>
        <TodaysScheduleComponent
          :our_upcoming_lectures="upcoming_lectures"
        ></TodaysScheduleComponent>
      </div>
    </div>
  </section>
</template>

<script>
import QuickStatisticsComponent from "../components/quick-statistics-component.vue";
import CalendarComponent from "../components/calendar-component.vue";
import TodaysScheduleComponent from "../components/todays-schedule-component.vue";
import NoticeBoardDisplayComponent from "../components/notice-board-display.vue";
import DashboardItemsSummary from "../components/dashboard-items-summary.vue";
import { addMonths } from "date-fns";
export default {
  components: {
    QuickStatisticsComponent,
    CalendarComponent,
    TodaysScheduleComponent,
    NoticeBoardDisplayComponent,
    DashboardItemsSummary,
  },

  data() {
    return {
      dataLoading: false,
      upcoming_lectures: [],
      notices: [],
      eventDays: [],
      isMonthCalendarLoading: false,
      calendarMonth: 1,
      calendarYear: 2021,
      dateToday: null,
      active_students_today: 0,
      student_count: 0,
      staff_count: 0,
    };
  },
  mounted() {
    this.fetchAdminDashboardHome();
  },
  methods: {
    async onChangeCalendarMonth(diff) {
      try {
        const date = new Date(+this.calendarYear, +this.calendarMonth - 1, 1);
        const newYearAndMonth = addMonths(date, diff);
        const newYear = newYearAndMonth.getFullYear();
        const newMonth = newYearAndMonth.getMonth() + 1;
        const isoMonth = newMonth <= 9 ? `0${newMonth}` : newMonth;
        newYearAndMonth.getMonth() + 1 <= 9
          ? "0" + (newYearAndMonth.getMonth() + 1)
          : newYearAndMonth.getMonth() + 1;
        this.isMonthCalendarLoading = true;
        let request = await this.$http.get(
          `staff/fetch-admin-monthly-events?month=${isoMonth}&year=${newYear}`
        );
        if (
          request.data.success &&
          request.data.message == "DAYS_FETCHED_SUCCESSFULLY"
        ) {
          this.eventDays = request.data.month_days;
          this.calendarYear = +newYear;
          this.calendarMonth = +isoMonth;
          // this.dateToday = +today[2];
        } else {
          this.showFailedMessage(
            "Unable to fetch Calendar",
            "Unable to fetch Calendar now, please try again later"
          );
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to fetch Calendar",
          "Unable to fetch Calendar now, please try again later"
        );
      } finally {
        this.isMonthCalendarLoading = false;
      }
    },

    async fetchAdminDashboardHome() {
      try {
        this.dataLoading = true;
        let request = await this.$http.get(
          `staff/fetch-admin-dashboard-home/${this.$store.state.userId}`
        );
        if (
          request.data.success &&
          request.data.message == "DASHBOARD_CONTENT_FETCHED_SUCCESSFULLY"
        ) {
          this.dateToday = request.data.date_today;
          const today = request.data.date_today.split("-");
          this.eventDays = request.data.days;
          this.calendarYear = +today[0];
          this.calendarMonth = +today[1];
          this.upcoming_lectures = request.data.upcoming_lectures;
          this.notices = request.data.notices;
          this.active_students_today = request.data.active_daily_students;
          this.student_count = request.data.student_count;
          this.staff_count = request.data.staff_count;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to load admin data",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.dataLoading = false;
      }
    },
  },
};
</script>

<style>
.el-carousel__arrow {
  background-color: #4b88dd;
}
</style>

<style scoped>
.in-the-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.round-ball-lg {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: black;
}
.bg-white {
  background-color: white;
}
.bg-blue {
  background-color: var(--el-app-primary);
  color: white;
}

.dashboard_inner_grid {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  margin: 20px 0px;
}

.dashboard_inner_grid > div {
  height: 100%;
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .dashboard_inner_grid {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 20px;
    margin: 20px 0px;
  }

  .dashboard_inner_grid > div {
    height: 100%;
  }
  .grid_row {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .grid_row > div {
    height: 100%;
    border-radius: 5px;
  }
  .grid_row > div:first-child {
    display: none;
  }
}
</style>
