export default (student: any) => {
  let fullName = "";
  if (student?.last_name) {
    fullName += student.last_name + " ";
  }

  if (student?.other_names) {
    fullName += student.other_names + " ";
  }

  if (student?.first_name) {
    fullName += student.first_name + " ";
  }

  return fullName.trim();
};
