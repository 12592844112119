export default [
  {
    label: "Certificate",
    value: "Certificate",
  },
  {
    label: "Higher Education Certificate",
    value: "Higher Education Certificate",
  },
  {
    label: "Bachelor's Degree",
    value: "Bachelor's Degree",
  },
  {
    label: "Master's Degree",
    value: "Master's Degree",
  },
  {
    label: "Diploma",
    value: "Diploma",
  },
  {
    label: "Postgraduate Diploma",
    value: "Postgraduate Diploma",
  },
];
