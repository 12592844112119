<template>
  <div
    class="container-fluid live-lectures-attendance px-0 h-100"
    style="text-align: left"
  >
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Live Lectures', 'Attendance']" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-12 text-left">
              <div style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  text-transform: uppercase;
                  margin-bottom: 12px;
                ">
                <strong>
                  <!-- {{ attendanceInfo.batch.course.course_code }}
                  {{ toMonthName(attendanceInfo.batch.month) }}/{{
                    attendanceInfo.batch.year
                  }} -->
                  Student Class Attendance
                </strong>
              </div>
            </div>
            <div
              class="pb-0 text-left col-12"
              style="
                font-weight: 500;
                text-transform: capitalize;
                font-size: 0.85em;
                color: rgba(0, 0, 0, 0.55);
              "
            >
              <b>Title</b>:
              {{ lecture_info.title }}
            </div>
            <div
              class="pb-0 text-left col-12"
              style="
                font-weight: 500;
                text-transform: capitalize;
                font-size: 0.85em;
                color: rgba(0, 0, 0, 0.55);
              "
            >
              <b>Modules</b>:
              <div
                v-for="(
                  moduleItem, index
                ) in lecture_info.course_units_with_batches"
                :key="index"
              >
                {{ moduleItem }}
              </div>
            </div>
            <div
              class="pb-0 text-left col-12 pt-3"
              style="
                font-weight: 500;
                text-transform: capitalize;
                font-size: 0.85em;
                color: rgba(0, 0, 0, 0.55);
              "
            >
              <b>By</b>: {{ lecture_info.lecturer.first_name }}
              {{ lecture_info.lecturer.last_name }}
            </div>
            <div
              class="pb-4 text-left col-12"
              style="
                font-weight: 500;
                text-transform: capitalize;
                font-size: 0.85em;
                color: rgba(0, 0, 0, 0.55);
              "
            >
              <b>Date</b>: {{ formattedDate(lecture_info.date) }} from
              {{ lecture_info.start_time }} to {{ lecture_info.end_time }}
            </div>
          </div>

          <div
            v-if="isProvisionalAttendance"
            class="row w-50 warning-notice mx-0 mb-3"
          >
            <div
              class="col-12 d-flex flex-column"
              style="margin-top: 10px"
            >
              <div class="description-text">
                <p>
                  <strong>Provisional Attendance</strong>
                </p>
              </div>
              <div
                class="d-flex"
                id="reason-container"
              >
                <span><i class="el-icon-warning"></i></span>
                <p class="ml-2">The final complete Lecture Report will be ready after 03.00AM today</p>
              </div>
            </div>
          </div>

          <div style="
              margin-bottom: 20px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            ">
            <div class="row w-100 d-flex">
              <div class="col-md-4 mb-3">
                <input
                  type="text"
                  class=""
                  v-model="filters[0].value"
                  placeholder="Search Student's Name"
                  style="height: calc(2.4xrem) !important"
                  spellcheck="off"
                />
              </div>
              <div class="col-3">
                <el-select
                  v-model="selectedFilter"
                  placeholder="Select"
                  class="d-block"
                >
                  <el-option
                    v-for="item in filterOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <hr />
          <div
            class="row mx-auto mb-4 text-right"
            style="width: 100%"
          >
            <div class="col-xl-3 pl-md-0 px-2 py-2 col-md-6">
              <div
                class="card"
                style="max-height: 110px"
              >
                <div class="row mt-3">
                  <div class="col-md-3 col-5 text-left">
                    <a
                      type="button"
                      class="btn-floating btn-md success-color ml-4"
                    >
                      <i
                        class="fas fa-check"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </div>
                  <div class="col-md-9 col-7 text-right pr-5">
                    <h5
                      class="ml-4 mt-4 mb-2 font-bold text-truncate"
                      style="font-size: 28px"
                    >
                      {{ totalAttended }}
                    </h5>
                    <p
                      class="font-small grey-text"
                      style="font-size: 12px"
                    >
                      Total Attended
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 px-2 py-2 col-md-6">
              <div
                class="card"
                style="max-height: 110px"
              >
                <div class="row mt-3">
                  <div class="col-md-3 col-5 text-left">
                    <a
                      type="button"
                      class="
                        btn-floating
                        z-depth-0
                        btn-md
                        deep-orange
                        darken-3
                        ml-4
                      "
                    >
                      <i
                        class="fas fa-close"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </div>
                  <div class="col-md-9 col-7 text-right pr-5">
                    <h5
                      class="ml-4 mt-4 mb-2 font-bold text-truncate"
                      style="font-size: 28px"
                    >
                      {{ totalMissed }}
                    </h5>
                    <p
                      class="font-small grey-text"
                      style="font-size: 12px"
                    >
                      Total Missed
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-loading="fetchingLiveClassId"
            style="width: 100%"
          >
            <data-tables
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [25, 50, 100] }"
              type="expand"
              :data="attendanceReport"
              :filters="filters"
              ref="lecturesTable"
            >
              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getLiveLectures"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Live lecture Attendance Now. Please click here
                  to retry
                </div>
                <div v-if="!loading && !loadingError">No Lecture Attendance</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
                width="60px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Student Name"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.first_name }}
                    {{ scope.row.last_name }}
                  </span>

                  <!-- <span>{{ scope.row.title }}</span> -->
                </template>
              </el-table-column>

              <el-table-column
                label="Registration No."
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.registration_no }}

                  </span>

                  <!-- <span>{{ scope.row.title }}</span> -->
                </template>
              </el-table-column>
              <el-table-column
                label="Intake"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="140px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.intake }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Module"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="140px"
              >
                <template slot-scope="scope">
                  <span>{{
                   scope.row.module
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Attended"
                width="80px"
                class-name="text-center"
                label-class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    :content="scope.row.student_attendance_reports.is_attended ? 'Attended' : 'Missed'"
                    placement="top"
                  >
                    <i
                      :class="{
                        'el-icon-close color-red': !scope.row.student_attendance_reports.is_attended,
                        'el-icon-check color-green': scope.row.student_attendance_reports.is_attended,
                      }"
                      style="font-size: 20px; cursor: pointer; color: #ffbb33"
                    ></i>
                  </el-tooltip>
                </template>
              </el-table-column>

              <el-table-column
                label="Check-in time"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="140px"
                prop="course_unit_code"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.student_attendance_reports.time_in || "N/A" }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Total Duration"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="130px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.student_attendance_reports.duration_attended || "N/A" }} </span>
                </template>
              </el-table-column>

              <el-table-column
                label="% Attendance"
                width="120px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.student_attendance_reports.percentage_attended || "N/A" }}</span>
                </template>
              </el-table-column>
            </data-tables>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import { format, parseISO } from "date-fns";
import BreadCrumbComponent from "../components/bread-crumb-component";
import getMonthName from "../helpers/getMonthName";
// import VueShowMore from "@/components/vue-show-more.vue"

export default {
  components: { ScaleOut, BreadCrumbComponent /* , VueShowMore */ },

  data() {
    return {
      selectedFilter: "Show All",
      fetchingLiveClassId: null,
      isLectureDetailsModelVisible: false,
      lectureDetailsLoading: true,
      filterOptions: ["Show All", "Show Only Missed", "Show Only Attended"],
      lecture_info: {},
      loading: false,
      isProvisionalAttendance: false,
      loadingError: false,
      attendanceInfo: null,
      tableProps: {
        border: true,
        stripe: true,
        // defaultSort: {
        //   prop: "date",
        //   order: "ascending",
        // },
      },

      filters: [
        {
          value: "",
          filterFn: (row, filterItem) => {
            return `${row.first_name} ${row.last_name}`
              .toLowerCase()
              .includes(filterItem.value.toLowerCase().trim());
          },
        },
        {
          prop: "title",
          value: "",
        },
        {
          prop: "title",
          value: "",
        },
      ],
    };
  },
  watch: {
    searchBy() {
      this.filters[0].value = "";
      // this.$refs.lecturesTable.clearFilter();
    },
  },
  computed: {
    toMonthName() {
      return (monthNum) => {
        return getMonthName(monthNum).toUpperCase();
      };
    },
    totalAttended() {
      return this.attendanceInfo.filter(
        (report) => report.student_attendance_reports.is_attended
      ).length;
    },
    totalMissed() {
      return this.attendanceInfo.filter(
        (report) => !report.student_attendance_reports.is_attended
      ).length;
    },
    attendanceReport() {
      return this.attendanceInfo.filter((report) => {
        if (this.selectedFilter === "Show Only Missed") {
          return !report.student_attendance_reports.is_attended;
        }
        if (this.selectedFilter === "Show Only Attended") {
          return report.student_attendance_reports.is_attended;
        }

        return true;
      });
    },

    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy");
    },
  },
  created() {
    if (Object.keys(this.$route.params).length > 0) {
      this.attendanceInfo = this.$route.params.report.attendance;
      this.lecture_info = this.$route.params.report.lecture_info;
      this.isProvisionalAttendance = this.$route.params.report.is_provisional;
    } else return this.$router.replace({ name: "LiveLectures" });
  },
  methods: {
    beforeDialogClose() {
      this.isLectureDetailsModelVisible = false;
    },
  },
};
</script>

<style>
.live-lectures-attendance .row .el-select .el-input__inner {
  cursor: pointer;
  height: 36px !important;
  width: 100% !important;
  padding-left: 10px !important;
  text-transform: capitalize;
}
.live-lectures-attendance .el-button--primary {
  color: #fff;
  background-color: #409eff !important;
  border-color: #409eff !important;
}
</style>

<style scoped>
.color-red {
  color: red !important;
}
.color-green {
  color: rgb(7, 206, 7) !important;
}

input[type="text"] {
  width: 100%;
  height: 36px !important;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}
</style>
