import { RoleType } from "@/enums/roleType.enum";

export const getFormattedRole = (role: RoleType) => {
  switch (role) {
    case RoleType.VC:
      return "Vice Chancellor";
    case RoleType.AR:
      return "Assistant Registrar";
    case RoleType.HOD:
      return "Head of Department";
    case RoleType.SUPER_ADMIN:
      return "Super Admin";
    case RoleType.DEAN:
      return "Dean";
    case RoleType.QA:
      return "Quality Assurance";
    case RoleType.IT:
      return "Information Technology";
    case RoleType.LECTURER:
      return "Lecturer";
    case RoleType.BURSAR:
      return "Bursar";
    case RoleType.HEAD_BURSAR:
      return "Head Bursar";
    case RoleType.HR:
      return "Human Resources";
    case RoleType.EXAMS:
      return "Examinations";
    case RoleType.STUDENT_APPLICATIONS:
      return "Student Applications";
    case RoleType.DEAN_OF_STUDENTS:
      return "Dean of Students";
    case RoleType.WEBSITE_ADMIN:
      return "Website Admin";
    case RoleType.NON_TEACHING_STAFF:
      return "Non-Teaching Staff";
    case RoleType.CAFETERIA_ADMIN:
      return "Cafeteria Admin";
    default:
  }
};
