<template>
  <div class="side-bar-container">
    <div class="side-bar-title pl-5 pl-md-0">CONVERSATIONS</div>

    <div
      class="side-bar"
      id="chat-side-bar"
    >
      <div
        class="d-flex justify-content-center align-self-center"
        v-if="isLoadingSideBarChats"
      >
        <ScaleOut
          :background="'#1F4EA1'"
          class="mx-auto"
        />
      </div>

      <div
        class="no-messages-banner"
        v-if="chats.length === 0"
      >
        <div class="d-flex justify-content-center align-self-center">
          <div class="no-messages-banner-text">
            No conversations yet ...
          </div>
        </div>
      </div>

      <ul infinite-scroll-immediate="false" v-infinite-scroll="fetchMoreConversations" infinite-scroll-distance="1">
        <li
          v-for="(chat,index) in chats"
          v-bind:key="index"
        >

          <div
            @click.prevent="selectChat(chat.chat_conversation_id)"
            class="side-chat-details"
            :class="{ 'selected-chat': selectedMessageId === chat.chat_conversation_id }"
          >
            <div>
              <span class="name-icon">{{chat.creator.first_name[0]}}</span>
            </div>

            <div
              class="chat-details side-bar-messages"
              :class="{ 'bold-text': chat.has_unread_messages}"
            >
              <div class="text-truncate">{{ chat.creator.first_name  }} {{chat.creator.last_name}}</div>

              <p class="message-text">
                <span>{{chat.last_message}}</span>
              </p>
            </div>

            <div
              id="date-container"
              class="text-right"
            >
              <span id="chat-date">{{formattedDate(chat.last_activity_date)}}</span>
            </div>
          </div>

        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import { format, parseISO } from "date-fns";

export default {
  name: "chat-side-bar",
  props: {
    chats: {
      type: Array,
      required: true,
    },
    isDrawerOpen: {
      type: Boolean,
    },
    selectedMessageId: {
      type: String,
      required: true,
    },
    isLoadingSideBarChats: {
      type: Boolean,
      required: true,
    },
  },
  components: { ScaleOut },
  data() {
    return {
      textInput: "",
      selectedChat: null,
      messages: [],
    };
  },
  methods: {
    selectChat(chatId) {
      //emit an event with the chat id
      this.$emit("show-main-window");
      this.$emit("chat-selected", chatId);
      if (this.isDrawerOpen) {
        this.$emit("close-drawer", chatId);
      }
    },
    formattedDate(dateIso) {
      const date = format(parseISO(dateIso), "do MMM");
      //check if it is today and show the time instead
      const today = format(new Date(), "do MMM");
      if (date === today) {
        return format(parseISO(dateIso), "HH:mm a");
      }
      return date;
    },

    fetchMoreConversations(){
      this.$emit("fetch-more-conversations");
    }
  },
};
</script>

<style scoped>
#chat-date {
  font-size: 0.7em;
  font-weight: 100;
  color: #8c8c8c;
}

#date-container {
  width: 55px;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  margin-top: 15px;
}

.message-text {
  margin-bottom: 0.5px;
  font-size: 11.5px;
}
.no-messages-banner-text {
  font-size: 1rem;
  color: #1f4ea1;
  font-style: italic;
  opacity: 0.5;
}
.side-bar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;
  margin-top: 12vh;
}
.side-bar {
  overflow-y: auto;
  max-height: 80vh;
  height: 98vh;
  align-items: flex-start;
  align-self: flex-start;
  align-content: flex-start;
}

.side-bar-title {
  font-weight: 800;
  margin-bottom: 10px;
  /* width: 100%; */
  color: #131414;
  font-size: 12px;
}

ul {
  list-style-type: none;
  padding: 0px 5px;
  margin: 0px 5px;
}

.side-chat-details {
  display: flex;
  overflow: hidden;
  gap: 12px;
  max-width: 350px;
  cursor: pointer;
  align-items: center;
  align-content: center;
}

.side-bar-messages {
  /* display: flex;
  flex-direction: column; */
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  font-size: 0.9em;
  text-overflow: ellipsis;
  cursor: pointer;
  margin-top: 14px;
}

/* hover on side bar message */
.side-chat-details:hover {
  background-color: #e0dddd;
}

.selected-chat {
  background-color: #e8e8e8;
}

.bold-text {
  font-weight: bold;
  font-size: 1em;
}

.name-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--el-app-primary);
  color: white;
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
  font-size: 0.8em;
  margin-left: 10px;
}

.chat-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  align-content: flex-start;
  width: 50%;
}

.message {
  font-size: 0.8em;
}

@media (max-width: 768px) and (max-width: 1024px) {
  .side-bar-messages {
    max-width: 110px !important;
  }

  .chat-details {
    width: 50%;
  }
  .side-bar-messages {
    max-width: 110px;
  }
}
</style>