<template>
  <div class="container-fluid">
    <div class="row w-100">
      <div class="row w-100" style="margin: 30px 27px 0px 27px">
        <div class="col-12 text-left pb-3">
          <BreadCrumbNavigation :pageTitles="['Live Lectures', 'Recorded']" />
        </div>

        <!-- VIDEO RESOURCES -->
        <div class="row w-100 mx-0">
          <div
            class="pb-0 text-left col-12"
            style="
              font-weight: 600;
              text-transform: capitalize;
              font-size: 1.2em;
            "
          >
            {{ $route.params.recordedLectureInfo.title }} (Recorded)
          </div>
          <div
            class="pb-0 text-left col-12"
            style="
              font-weight: 500;
              text-transform: capitalize;
              font-size: 0.95em;
              color: rgba(0, 0, 0, 0.8);
            "
          >
            <div
              v-for="(courseUnit, index) in $route.params.recordedLectureInfo
                .course_units_with_batch"
              :key="index"
            >
              {{ courseUnit }}
            </div>
          </div>
          <div
            class="pb-0 text-left col-12"
            style="
              font-weight: 500;
              text-transform: capitalize;
              font-size: 0.85em;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            By {{ $route.params.recordedLectureInfo.lecturer_name }}
          </div>
          <div
            class="pb-4 text-left col-12"
            style="
              font-weight: 500;
              text-transform: capitalize;
              font-size: 0.85em;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            {{ formattedDate($route.params.recordedLectureInfo.date) }} from
            {{ $route.params.recordedLectureInfo.start_time }} to
            {{ $route.params.recordedLectureInfo.end_time }}
          </div>

          <div class="col-12">
            <vue-plyr>
              <video controls playsinline>
                <source
                  :src="$route.params.recordedLectureInfo.recording_url"
                  type="video/mp4"
                />
                <track
                  default
                  kind="captions"
                  label="English captions"
                  srclang="en"
                />
              </video>
            </vue-plyr>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbNavigation from "../components/bread-crumb-component.vue";
import { format, parseISO } from "date-fns";

export default {
  components: {
    BreadCrumbNavigation,
  },
  data() {
    return {};
  },

  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMMM yyyy");
    },
  },

  created() {
    //If there's No Recorded Lecture Info, e.g page has reloaded, go back to previous page
    if (!this.$route.params?.recordedLectureInfo) {
      return this.$router.replace({ name: "LiveLectures" });
    }
  },
  methods: {},
};
</script>

<style scoped>
.direction_arrows {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: rgb(214, 226, 238);
  color: var(--el-app-primary);
  cursor: pointer;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.direction_arrows i {
  font-size: 15px;
}
</style>