export default [
    {
        label: "Academic Upgrading",
        value: "Academic Upgrading",
    },
    {
        label: "Undergraduate",
        value: "Undergraduate",
    },
    {
        label: "Postgraduate",
        value: "Postgraduate",
    }
];