<template>
  <el-dialog
    title="Lecture Attendance Stats"
    :visible="isVisible"
    :destroy-on-close="true"
    @open="getLiveLectureStats"
    :before-close="closeDialog"
  >
    <div v-loading="isBusy" class="row w-100 mx-0">
      <div class="col-md-7">
        <div class="mb-2">
          <strong style="font-weight: 500">Name</strong>: {{ name }}
        </div>
        <div class="mb-2">
          <strong style="font-weight: 500">Date</strong>:
          <b>{{ liveClassStats.startDate }}</b> to
          <b>{{ liveClassStats.endDate }}</b>
        </div>
        <div style="height: 250px">
          <div class="d-flex justify-content-between align-items-center">
            <div id="chart" class="w-100">
              <div class="w-100 h-100">
                <VueApexCharts
                  width="100%"
                  :key="chartKey"
                  style="height: 100%; pointer-events: none !important"
                  type="area"
                  :options="options"
                  :series="series"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 text-center">
        <div class="row w-100 mx-0">
          <!-- <div class="col-6 px-0 tex-center">
            <div class="title-item text-truncate">{{ attendanceQuality }}%</div>
            <div class="subtitle-item">Attendance <br /> Quality</div>
          </div> -->
          <div class="col-6 px-0 tex-center">
            <div class="title-item text-truncate">
              {{ percentageAttended }}%
            </div>
            <div class="subtitle-item">
              Classes <br />
              Attended
            </div>
          </div>
        </div>
        <div class="row w-100 mx-0 mt-5">
          <div class="col-6 px-0 tex-center">
            <div class="title-item text-truncate">
              {{ liveClassStats.totalLiveClasses || 0 }}
            </div>
            <div class="subtitle-item">
              Classes <br />
              Scheduled
            </div>
          </div>
          <div class="col-6 px-0 tex-center">
            <div class="title-item text-truncate">
              {{ liveClassStats.totalLiveClassesMissed || 0 }}
            </div>
            <div class="subtitle-item">
              Classes <br />
              Missed
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  emits: ["close-dialog"],
  components: {
    VueApexCharts,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },

    userId: {
      type: String,
      required: true,
    },

    userType: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    dateRange: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      chartKey: 0,
      liveClassStats: {},
      options: {
        dataLabels: { enabled: false },
        tooltip: { enabled: false },
        zoom: {
          enabled: false,
        },
        chart: {
          toolbar: { show: false },
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          labels: { show: false },
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5,
        },
      },
      series: [
        {
          name: "series-1",
          // data: [30, 20, 22, 11, 0, 4, 5, 20, 21, 76, 88, 54, 0, 0, 3, 5, 43],
          data: [],
        },
      ],
    };
  },

  computed: {
    percentageAttended() {
      if (!this.liveClassStats?.totalLiveClasses) return 0;
      return +(
        (this.liveClassStats.totalLiveClassesAttended /
          this.liveClassStats?.totalLiveClasses) *
        100
      ).toFixed(1);
    },

    attendanceQuality() {
      if (!this.liveClassStats?.percentageAttendanceStats) return 0;

      const actualAttendedStats =
        this.liveClassStats?.percentageAttendanceStats?.filter(
          (stats) => stats > 0
        );

      const totalPercentageAttendance = actualAttendedStats
        ?.filter((stats) => stats > 0)
        ?.reduce((a, b) => a + b, 0);
      if (!totalPercentageAttendance) return 0;

      return +(totalPercentageAttendance / actualAttendedStats?.length).toFixed(
        1
      );
    },
  },

  methods: {
    async getLiveLectureStats() {
      try {
        this.isBusy = true;
        let request = await this.$http.get(
          `${this.userType}/live-lecture-attendance-stats-for-a-given-period/${this.userId}?startDate=${this.dateRange[0]}&endDate=${this.dateRange[1]}`
        );
        if (
          request.data.success &&
          request.data.message ==
            "Live classes attendance stats fetched successfully"
        ) {
          this.liveClassStats = request.data.liveClassStats;
          this.series[0].data =
            request.data.liveClassStats?.percentageAttendanceStats || [];
          this.chartKey++;
        }
      } finally {
        this.isBusy = false;
      }
    },

    closeDialog() {
      this.liveClassStats = {};
      this.series = [{ name: "series-1", data: [] }];

      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped>
.title-item {
  font-weight: 600;
  font-size: 2em;
  color: rgb(0, 0, 0, 0.65);
}

.subtitle-item {
  font-size: 0.8em;
}
</style>
