<template>
  <div class="mx-0">
    <div
      class="bg-white"
      style="padding: 40px 20px"
    >
      <div style="text-align: left">
        <h6 style="font-weight: 600; color: var(--el-app-primary)">
          Today's Examination Statistics
        </h6>
      </div>
      <div style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px 0px;
              ">
        <div style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
          <el-progress
            type="dashboard"
            :percentage="percentageSubmitted"
          ></el-progress>
        </div>
        <div>
          <span style="
                    font-weight: 600;
                    font-size: 25px;
                    color: var(--el-app-primary);
                  ">{{totalSubmitted}}/{{totalStarted}}</span><br />
          <span><small>Submitted</small></span>
        </div>
      </div>
      <hr />
      <div style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              ">
        <div style="text-align: left">
          <span style="color: grey"><small>{{totalSubmittedOnline}}/{{totalSubmitted}} Submissions</small></span><br />
          <span style="color: grey"><small>Online ({{submittedOnlineAnswerSheetPercentage}}%)</small></span>
        </div>
        <div style="text-align: left">
          <span style="color: grey"><small>{{totalSubmittedFileUpload}}/{{totalSubmitted}} Submissions</small></span><br />
          <span style="color: grey"><small>File Upload ({{submittedDownloadedAnswerSheetPercentage}}%)</small></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
export default {
  data() {
    return {
      totalStarted: 0,
      totalSubmitted: 0,
      totalSubmittedOnline: 0,
      totalSubmittedFileUpload: 0,
      totalPendingSubmission: 0,
    };
  },

  computed: {
    percentageSubmitted() {
      if (this.totalStarted == 0) return 0;
      return +((this.totalSubmitted / this.totalStarted) * 100).toFixed(1);
    },

    submittedOnlineAnswerSheetPercentage() {
      if (this.totalSubmitted == 0) return 0;
      return +((this.totalSubmittedOnline / this.totalSubmitted) * 100).toFixed(
        1
      );
    },
    submittedDownloadedAnswerSheetPercentage() {
      if (this.totalSubmitted == 0) return 0;
      return +(
        (this.totalSubmittedFileUpload / this.totalSubmitted) *
        100
      ).toFixed(1);
    },
  },
  mounted() {
    // this.fetchDailyStatistics();
    this.listenForExamActivityChanges();
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    async fetchDailyStatistics() {
      try {
        const response = await this.$http.get("examinations/statistics");
        if (
          response.data.success &&
          response.data.message == "STATISTICS_RETRIEVED_SUCCESS_FULLY"
        ) {
          //alert("yeah")
          this.totalStarted =
            response.data.statistics.number_of_students_started;
          this.totalSubmitted =
            response.data.statistics.number_of_students_who_have_submitted;
          this.totalSubmittedOnline =
            response.data.statistics.number_of_students_who_submitted_online;
          this.totalSubmittedFileUpload =
            response.data.statistics.number_of_students_who_submitted_by_file_upload;
          this.totalPendingSubmission =
            response.data.statistics.number_of_students_who_have_not_submitted_but_started;
        }
      } catch (error) {
        //console.log("ignore errors");
      }
    },

    listenForExamActivityChanges() {
      this.unsubscribe = firebase
        .firestore()
        .doc(`examinations/tE8Jfvb1JLU25dF7IJWX`)
        .onSnapshot({ includeMetadataChanges: false }, (docSnapshot) => {
          if (docSnapshot.metadata.fromCache) return;
          this.fetchDailyStatistics();
          if (!docSnapshot.exists) {
            //console.
          }
        });
    },
  },
};
</script>

<style scoped>
.grid_row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.grid_row > div {
  height: 100%;
  border-radius: 5px;
}
</style>