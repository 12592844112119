<template>
  <div class="choose-period-dialog">
    <el-dialog title="Choose Period"
      :visible="showDialog"
      :destroy-on-close="true"
      :before-close="handleClose">

      <div>
        <div class="row">
          <div class="col-12">
            <div class="block w-100">
              <div class="pb-2 demonstration">Select period </div>
              <el-date-picker type="daterange"
                class="w-100 custom-picker"
                v-model="startAndEndDate"
                :format="'dd MMMM yyyy'"
                :value-format="'yyyy-MM-dd'"
                :picker-options="pickerOptions"
                range-separator="To"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                style="height: 40px !important">
              </el-date-picker>
            </div>
          </div>
          <div class="pt-4 col-12 align-items-end"
            style="display:flex;">
            <el-button style="background-color: var(--el-app-primary); color: white; width: 100%; height: 2.6rem;"
              class="z-depth-0"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
              :disabled="!startAndEndDate"
              @click="onContinue">Continue</el-button>
          </div>
        </div>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import { isToday, differenceInDays, parseISO } from "date-fns";
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      downloading: false,
      startAndEndDate: null,
      pickerOptions: {
        disabledDate(time) {
          //Disable if the date is greater than or equal to today
          if (isToday(time)) {
            return true;
          }

          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    };
  },
  methods: {
    handleClose() {
      this.$emit("close-dialog");
    },

    async onContinue() {

      //Ensure the time range is not greater than 6 Months
      const diffInDays = differenceInDays(parseISO(this.startAndEndDate[1]), parseISO(this.startAndEndDate[0]));

      if (diffInDays > 90) {
        return this.showWarningMessage("Too Large", "The time range cannot be greater than 3 months");
      }

      this.$emit("continue", this.startAndEndDate)
    },
  },
};
</script>

<style>
.choose-period-dialog .el-dialog {
  width: 30% !important;
}

.el-picker-panel__sidebar {
 width: 123px !important;
}
</style>
