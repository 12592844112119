import { SSE_BASE_URL } from "../../../helpers/constants";
class serverEventHandler {
  constructor(urlEndPoint) {
    this.serverUrl = `${SSE_BASE_URL}${urlEndPoint}`;
    this.eventSource = new EventSource(this.serverUrl);
  }

  listen(eventName, callback) {
    this.eventSource.addEventListener(eventName, callback);
  }

  reconnect() {
    this.eventSource.close();
    this.eventSource = new EventSource(this.serverUrl);
  }

  close() {
    this.eventSource.close();
  }
}

export default serverEventHandler;
