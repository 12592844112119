<template>
  <div class="container-fluid">
    <div class="row">
      <div class="TabpaddingTop">
        <div class="text-left">
          <BreadCrumbNavigation :pageTitles="['Faculties','Departments','Courses', 'Modules', course_unit_title ]" />
        </div>
        <div class="mt-4 mb-4">
          <div class="">
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <span style="color: grey; font-weight: 600; font-size: 1.2em;">{{ course_unit_title ? course_unit_title:'...', }}</span>
              <!-- <UploadContentComponent
                @on-refresh="loadCourseUnitResources"
                @on-resource-added="onResourceAdded"
                :courseUnitId="cuId"
              /> -->
            </div>
          </div>
        </div>

        <!-- RESOURCES PRELOADER -->
        <div v-if="isLoading && !isLoadingError" class="col-12">
          <ResourcesPreloader />
        </div>

        <!-- TABS SECTION -->
        <div v-if="!isLoading && !isLoadingError && !currentResource">
          <div>
            <DocumentResources
              :resources="resources"
              @resource-deleted="onResourceDeleted"
              @on-select-resource="onSelectResource"
              :title="'Modules Resources'"
            />
          </div>
        </div>

        <!-- ERROR SECTION FOR LOADING ERRORS -->
        <div v-if="!isLoading && isLoadingError" class="col-12 mt-5" >
          <span style="font-weight: 600">Unable to get Resources</span>
          <div class="pt-2" style="font-size: 0.9em">
            Failed to get Resources right now. Click below to retry
          </div>
          <div class="col-12 mt-1">
            <button
              type="button"
              class="btn z-depth-0"
              @click="loadCourseUnitResources"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
              style="
                width: 250px;
                text-transform: capitalize;
                height: 45px;
                border-radius: 4px;
                background-color: red;
                font-weight: 500;
              "
            >
              <span style="position: relative; bottom: 1px">Try Again</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentResources from "../components/course-unit-resources/document-resources.vue";
import BreadCrumbNavigation from "../components/bread-crumb-component.vue";
import ResourcesPreloader from "../components/course-unit-resources/resources-preloader.vue";
// import UploadContentComponent from "../components/upload-content-component";
export default {
  components: {
    DocumentResources,
    BreadCrumbNavigation,
    ResourcesPreloader,
    // UploadContentComponent,
  },
  data() {
    return {
      currentResource: "",
      resourceTitle: "",
      file: "",
      activePage: 1,
      resources: [],
      isLoading: true,
      isLoadingError: false,
      cuId: this.$route.params.cuId, //this is the department id from the browser
      course_unit_title:  this.$route.params.course_unit_title,
      course_title: "",
    };
  },

  mounted() {
    this.courseUnitName = this.$route.params.courseUnitName;
    this.loadCourseUnitResources();
  },

  methods: {

    onResourceAdded() {
      this.loadCourseUnitResources();
    },

    onResourceDeleted(resourceID) {
      this.resources = this.resources.filter(
        (resource) => resource.resource_id != resourceID
      );
    },

    onFileChange(e) {
      this.file = e.target.files[0];
    },

    onSelectResource(resource) {
      if (resource.is_available == true) {
        this.$router.push({ name: "ResourceDetails", params: { resource } });
        this.currentResource = resource;
      } else {
        this.showWarningMessage(
          "Not available",
          "Unable to reach this Resource now"
        );
      }
    },

    async loadCourseUnitResources() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `resources/fetch-by-course-unit/${this.cuId}`
        );
        if (
          request.data.success &&
          request.data.message === "COURSE_UNIT_RESOURCES RETRIEVED SUCCESSFULLY"
        ) {
          this.resources = request.data.course_unit_resources;
          // this.course_unit_title = "Resources"
          // this.course_title = "Modules";
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Failed",
          "Unable to fetch Resources now, please try again"
        );
      } finally {
        this.isLoading = false;
      }
    },
    
  },

};
</script>

<style scoped>
.direction_arrows {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: rgb(214, 226, 238);
  color: var(--el-app-primary);
  cursor: pointer;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.direction_arrows i {
  font-size: 15px;
}

#the-tab-section {
  width: 80%;
  position: fixed;
  background-color: #eeeeee;
  z-index: 100;
  top: 60px;
}

#the-tab {
  width: 50%;
}

.TabpaddingTop {
  margin: 50px 27px 0px 27px;
  width: 100%;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 130px 10px 0px 10px;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 130px 10px 0px 10px;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 130px 10px 0px 10px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 130px 5px 0px 5px;
  }
}
</style>