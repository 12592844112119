<template>
  <div
    class="container-fluid px-0 h-100"
    style="text-align: left;"
  >
    <article>
      <section style="padding-top: 50px;">
        <div class="container">

          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Requests']" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-12 text-left">
              <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary); margin-bottom: 15px; ">
                <strong>User Requests</strong>
              </div>
            </div>
          </div>

          <div style="margin-bottom:20px; display: flex; align-items: center; justify-content: space-between;">
            <div class="row w-100 d-flex">
              <div class="col-md-5 mb-3">
                <input
                  type="text"
                  class="search_by_input"
                  v-model.trim="filters[0].value"
                  placeholder="Search"
                  spellcheck="off"
                />
              </div>
            </div>
          </div>
          <hr />

          <div class="table-responsive">
            <data-tables
              :data="requests"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >

              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getRequests"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load requests Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No requests</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="User"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <div
                    @click="showRequest(scope.row.id, scope.row.status, scope.row.first_name, scope.row.last_name, scope.row.email, scope.row.oldValues.first_name, scope.row.oldValues.last_name, scope.row.oldValues.email, scope.row.reason, formattedDate(scope.row.createdAt))"
                    style="cursor: pointer;"
                  >
                    <span style="font-weight: 700;">First Name: </span><span>{{ scope.row.oldValues.first_name }}</span><br />
                    <span style="font-weight: 700;">Last Name: </span><span>{{ scope.row.oldValues.last_name }}</span><br />
                    <span style="font-weight: 700;">Email: </span><span>{{ scope.row.oldValues.email }}</span><br />
                    <small><span>{{scope.row.user_type}}</span></small>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="Reason"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                class-name="text-left"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.reason }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Time sent"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <span>{{ formattedDate(scope.row.createdAt) }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    @click="showRequest(scope.row.id, scope.row.status, scope.row.first_name, scope.row.last_name, scope.row.email, scope.row.oldValues.first_name, scope.row.oldValues.last_name, scope.row.oldValues.email, scope.row.reason, formattedDate(scope.row.createdAt))"
                    style="cursor: pointer;"
                    icon="el-icon-message"
                    round
                  >View Request</el-button>
                </template>
              </el-table-column>

            </data-tables>
          </div>

        </div>
      </section>

      <section>
        <el-dialog
          title="Request info:"
          :visible.sync="showRequestModal"
        >
          <div v-loading="deleting">
            <table
              class="table table-bordered  table-striped table-hover"
              style="width:100%"
            >
              <tr class="table-dark">
                <th><span style="font-weight: 700;"> </span></th>
                <th><span style="font-weight: 700;">Old Value</span></th>
                <th><span style="font-weight: 700;">New Value</span></th>
              </tr>
              <tr>
                <td><span style="font-weight: 700;">First Name: </span></td>
                <td>{{oldFirstName}}</td>
                <td>{{firstName}}</td>
              </tr>
              <tr>
                <td><span style="font-weight: 700;">Last Name: </span></td>
                <td>{{oldLastName}}</td>
                <td>{{lastName}}</td>
              </tr>
              <tr>
                <td><span style="font-weight: 700;">Email: </span></td>
                <td>{{oldEmail}}</td>
                <td>{{email}}</td>
              </tr>
            </table>

            <div>
              <hr />
              <p>Reason:</p>
              <p>{{reason}}</p>
            </div>

            <div>
              <hr />
            </div>
            <div
              slot="footer"
              class="dialog-footer"
              style="display: flex; align-items: center; justify-content: space-between;"
            >
              <el-row>
                <el-tooltip
                  content="Approve request"
                  placement="top"
                >
                  <el-button
                    v-if="status == 'Pending'"
                    @click="approveRequest(request_id)"
                    type="primary"
                    icon="el-icon-edit"
                    round
                  >Approve</el-button>
                </el-tooltip>
                <el-tooltip
                  content="Request Approved"
                  placement="top"
                >
                  <el-button
                    v-if="status == 'Approved'"
                    type="success"
                    icon="el-icon-check"
                    round
                  >Approved</el-button>
                </el-tooltip>
                <el-tooltip
                  content="Request Denied"
                  placement="top"
                >
                  <el-button
                    v-if="status == 'Rejected'"
                    type="danger"
                    icon="el-icon-close"
                    round
                  >Rejected</el-button>
                </el-tooltip>
                <el-tooltip
                  content="Deny request"
                  placement="top"
                >
                  <el-button
                    v-if="status == 'Pending'"
                    @click="denyRequest(request_id)"
                    type="warning"
                    icon="el-icon-warning-outline"
                    round
                  >Deny</el-button>
                </el-tooltip>
                <el-tooltip
                  content="Delete Request"
                  placement="top"
                >
                  <el-button
                    type="danger"
                    @click="deleteRequest(request_id)"
                    icon="el-icon-delete"
                    round
                  >Delete</el-button>
                </el-tooltip>
              </el-row>
              <small>Sent on: <span>{{ createdAt }}</span></small>
            </div>
          </div>
        </el-dialog>
      </section>

    </article>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbComponent from "../components/bread-crumb-component";
import { format, parseISO } from "date-fns";

export default {
  components: { ScaleOut, BreadCrumbComponent },
  mixins: [validationMixin],

  data() {
    return {
      addFacultyModal: false,
      editFacultyModal: false,
      showRequestModal: false,
      loading: false,
      loadingError: false,
      submiting: false,
      deleting: false,
      responseData: "",
      requests: [],

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
        defaultSort: {
          prop: "id",
          order: "descending",
        },
      },

      filters: [
        {
          prop: "",
          value: "",
          filterFn: (row, filterItem) => {
            return `${row.first_name} ${row.last_name}`
              .toLowerCase()
              .includes(filterItem.value.toLowerCase().trim());
          },
        },
      ],

      request_id: "",
      status: "",
      firstName: "",
      oldFirstName: "",
      lastName: "",
      oldLastName: "",
      email: "",
      oldEmail: "",
      reason: "",
      createdAt: "",
    };
  },

  computed: {
    createdBy() {
      return (createdBy) => {
        if (!createdBy) return "N/A";
        return `${createdBy.first_name} ${createdBy.last_name}`;
      };
    },

    formattedDate() {
      return (dateString) =>
        format(parseISO(dateString), "do MMMM yyyy HH:mm:ss");
    },
  },

  validations: {
    title: {
      required,
    },
    title_edit: {
      required,
    },
  },

  mounted() {
    this.getRequests();
  },

  methods: {
    async getRequests() {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(`info-update/fetch-all`);
        if (
          request.data.success &&
          request.data.message == "REQUESTS_FETCHED_SUCCESSFULLY"
        ) {
          this.requests = request.data.requests;
        } else {
           this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load requests",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    approveRequest(id) {
      //Request from - to:
      this.$confirm(
        "This will permanently change user info. Do you want to continue?",
        "Approve",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.deleting = true;
          this.$http.put(`info-update/approve-request/${id}`).then(
            (response) => {
              if (
                response.data.success &&
                response.data.message == "REQUEST_APPROVED_SUCCESSFULLY"
              ) {
                this.getRequests();
                this.deleteRequest(`${id}`);
                this.showSuccessMessage(
                  "Success",
                  "Request approved successfully!"
                );
                this.showRequestModal = false;
                this.deleting = false;
              }
            },
            () => {
              // eslint-disable-line no-unused-vars
              this.showFailedMessage("Operation Failed", "Unable to approve request");
              this.deleting = false;
            }
          );
        })
        .catch(() => {});
    },

    denyRequest(id) {
      this.$confirm(
        "User info update request will be denied and deleted. Do you want to continue?",
        "Deny",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.deleting = true;
          this.$http.put(`info-update/reject-request/${id}`).then(
            (response) => {
              if (
                response.data.success &&
                response.data.message == "REQUEST_REJECTED_SUCCESSFULLY"
              ) {
                this.getRequests();
                this.deleteRequest(`${id}`);
                this.showSuccessMessage(
                  "Success",
                  "Request denied successfully!"
                );
                this.deleting = false;
                this.showRequestModal = false;
              }
            },
            () => {
              // eslint-disable-line no-unused-vars
              this.showFailedMessage("Operation Failed", "Unable to deny request");
              this.deleting = false;
            }
          );
        })
        .catch(() => {});
    },

    deleteRequest(id) {
      // return; // eslint-disable-line no-unreachable
      this.$confirm(
        "This will permanently delete this Request. Do you want to Continue?",
        "Delete",
        {
          // eslint-disable-line no-unreachable
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.deleting = true;
          this.$http.delete(`info-update/delete-request/${id}`).then(
            (response) => {
              if (
                response.data.success &&
                response.data.message == "REQUEST_DELETED_SUCCESSFULLY"
              ) {
                this.deleting = false;
                this.getRequests();
                this.showSuccessMessage(
                  "Success",
                  "Request deleted successfully!"
                );
                this.showRequestModal = false;
              }
            },
            () => {
              // eslint-disable-line no-unused-vars
              this.showFailedMessage("Operation Failed", "Unable to delete Request");
              this.deleting = false;
            }
          );
        })
        .catch(() => {});
    },

    showRequest(
      request_id,
      status,
      first_name,
      last_name,
      email,
      Oldfirst_name,
      Oldlast_name,
      Oldemail,
      reason,
      createdAt
    ) {
      this.request_id = request_id;
      this.status = status;
      this.firstName = first_name;
      this.oldFirstName = Oldfirst_name;
      this.lastName = last_name;
      this.oldLastName = Oldlast_name;
      this.email = email;
      this.oldEmail = Oldemail;
      this.reason = reason;
      this.createdAt = createdAt;
      this.showRequestModal = true;
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.search_by_input {
  width: 400px !important;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_by_input {
    width: 100% !important;
  }
}
</style>
