<template>
  <div class="text-left upload-multiple-resource">
    <el-dialog
      :visible="isVisible"
      width="40%"
      :before-close="closeDialog"
      @open="showUploadContentDialog"
    >
      <div v-loading="loading">
        <el-form
          :model="resourceRuleForm"
          :rules="resourceRules"
          ref="resourceRuleForm"
          label-width="120px"
          class="demo-resourceRuleForm"
        >
          <div class="row">
            <div class="col-md-12">
              <!-- {{ resourceRuleForm.programmes | json }} -->
              <label>Choose Programmes</label>
              <el-form-item
                prop="programmes"
                style="
                  width: 100% !importatant;
                  margin: 0px !important;
                "
              >
                <el-select
                  v-model="resourceRuleForm.programmes"
                  multiple
                  placeholder="Select"
                  style="
                    width: 100% !importatant;
                    margin: 0px !important;
                  "
                >
                  <el-option
                    v-for="program in programmesEditData"
                    :key="program.course_id"
                    :label="program.title"
                    :value="program.course_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mt-4">
              <label>Choose Resource Target</label>
              <el-form-item prop="module_cohort">
                <el-radio-group v-model="resourceRuleForm.module_cohort">
                  <el-radio disabled label="Module"></el-radio>
                  <el-radio disabled label="Cohort"></el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mt-3">
              <el-form-item>
                <el-button
                  type="primary"
                  @click="continueToModuleOrCohort('resourceRuleForm')"
                  style="width: 100%"
                  >Continue</el-button
                >
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog
      title="Update Content"
      :visible.sync="dialogVisible2"
      width="40%"
      :before-close="handleClose"
    >
      <div v-loading="loading">
        <el-form
          :model="cohortRuleForm"
          :rules="cohortRules"
          ref="cohortRuleForm"
          label-width="120px"
          class="demo-cohortRuleForm"
        >
          <div class="row">
            <div class="col-md-12">
              <label>Choose Cohorts</label>
              <el-form-item prop="intakes">
                <el-select
                  v-model="cohortRuleForm.intakes"
                  multiple
                  placeholder="Select"
                  style="width: 100% !importatant"
                >
                  <el-option
                    v-for="intake in course_intake_batches"
                    :key="intake.course_intake_batch_id"
                    :label="intake.intake"
                    :value="intake.course_intake_batch_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mt-3">
              <el-form-item>
                <el-button
                  type="primary"
                  @click="continueToCohortResourceUpload()"
                  style="width: 100%"
                  >Continue</el-button
                >
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
    </el-dialog>

    <section>
      <el-dialog
        :visible.sync="ProgrammeDialog"
        width="40%"
        :before-close="handleClose"
      >
        <div v-loading="loading">
          <el-form
            :model="resourceRuleForm"
            :rules="resourceRules"
            ref="resourceRuleForm"
            label-width="120px"
            class="demo-resourceRuleForm"
          >
            <div class="row">
              <div class="col-md-12">
                <label>Choose Programmes</label>
                <el-form-item
                  prop="programmes"
                  style="
                    width: 100% !importatant;
                    margin: 0px !important;
                  "
                >
                  <el-select
                    v-model="resourceRuleForm.programmes"
                    multiple
                    placeholder="Select"
                    style="
                      width: 100% !importatant;
                      margin: 0px !important;
                    "
                  >
                    <el-option
                      v-for="program in programmesEditData"
                      :key="program.course_id"
                      :label="program.title"
                      :value="program.course_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-4">
                <label>Choose Resource Target</label>
                <el-form-item prop="module_cohort">
                  <el-radio-group v-model="resourceRuleForm.module_cohort">
                    <el-radio label="Module"></el-radio>
                    <el-radio label="Cohort"></el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-3">
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="continueToModuleOrCohort('resourceRuleForm')"
                    style="width: 100%"
                    >Continue</el-button
                  >
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </el-dialog>
    </section>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
const cityOptions = ["Shanghai", "Beijing", "Guangzhou", "Shenzhen"];
export default {
  mixins: [validationMixin],
  components: {},
  props: {
    resourceId: {
      type: String,
      required: true,
    },
    resourceTarget: {
      type: String,
      required: true,
    },
    isVisible: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      isUploadingFile: false,
      dialogVisible: false,
      ProgrammeDialog: false,
      // isUploadingFile: false,
      dialogVisible2: false,
      selectedFile: null,
      isDurationError: false,
      loading: false,
      loadingError: false,
      selectedResourceType: "",
      title: "",
      maturity_date: "",
      fileLists: [],
      course_intake_batches: [],
      programmesEditData: [],

      resourceTypes: [
        { value: "notes", label: "Notes" },
        { value: "videos", label: "Videos" },
        { value: "past-papers", label: "Past Papers" },
      ],

      checkAll: false,
      checkedCities: ["Shanghai", "Beijing"],
      cities: cityOptions,
      isIndeterminate: true,

      resourceRuleForm: {
        programmes: [],
        module_cohort: "",
      },

      cohortRuleForm: {
        intakes: [],
      },

      resourceRules: {
        programmes: [
          {
            required: true,
            message: "Please select programmes",
            trigger: "change",
          },
        ],
        module_cohort: [
          {
            required: true,
            message: "Please choose resource target.",
            trigger: "change",
          },
        ],
      },

      cohortRules: {
        intakes: [
          {
            required: true,
            message: "Please select Intakes",
            trigger: "change",
          },
        ],
      },

      module_cohort: "",
      programmes: [],
      intakes: [],
    };
  },

  validations: {
    selectedResourceType: { required },
    title: { required, maxLength: maxLength(100) },
    maturity_date: { required },
  },

  mounted() {},

  methods: {
    handleClose(done) {
      done();
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    handlePreview(file) {
      this.fileLists = [file];
      this.selectedFile = file;
    },
    handleRemove() {
      this.selectedFile = null;
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },

    showUploadContentDialog() {
      if (this.resourceTarget == "COURSE_UNIT") {
        this.resourceRuleForm.module_cohort = "Module";
      } else if (
        this.resourceTarget == "COURSE_UNIT_WITH_COURSE_INTAKE_BATCH"
      ) {
        this.resourceRuleForm.module_cohort = "Cohort";
      }
      this.getProgrammesByResourceId(this.resourceId);
    },

    async getProgrammesByResourceId(resourceId) {
      let resourceData = {
        resource_id: resourceId,
      };
      try {
        this.loading = true;
        this.loadingError = false;

        let request = await this.$http.post(
          `/courses/fetch-all-course-names`,
          resourceData
        );

        if (
          request.data.success &&
          request.data.message == "COURSES_NAMES_FETCHED_SUCCESSFULLY"
        ) {
          this.programmesEditData = request.data.courses;

          // let course_unit_with_intake_resources =
          //   request.data.resource.course_unit_with_intake_resources;
          //course_id: "NmBZ9NWLyRl"
          // has_supplied_resource: false
          // title: "Bachelor of Agriculture & Rural Innovation"

          let checkedProgrammes = this.programmesEditData.filter(
            (resource) => resource.has_supplied_resource == true
          );

          this.resourceRuleForm.programmes = checkedProgrammes.map((item) => {
            return `${item.course_id}`;
          });

        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
           this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load Cohorts",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async continueToModuleOrCohort(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.dialogVisible = false;
          if (this.resourceRuleForm.module_cohort == "Module") {
            let moduleData = {
              course_ids: this.resourceRuleForm.programmes,
              include_course_units: true,
            };
            try {
              this.loading = true;
              this.loadingError = false;

              let request = await this.$http.post(
                `courses/fetch-all-course-names`,
                moduleData
              );

              if (
                request.data.success &&
                request.data.message == "COURSES_NAMES_FETCHED_SUCCESSFULLY"
              ) {
                let selectedModules = request.data.courses;
                // this.$router.push({ path: `upload-resources-for-multiple-modules` });

                this.$router.push({
                  name: "UpdateResourcesForMultipleModules",
                  params: {
                    resourceId: this.resourceId,
                    selectedModules: selectedModules,
                  },
                });
              } else {
                this.$rollbar.warning(
                  "ADMIN FRONT END: Unexpected API response while getting server response",
                  request.data,
                  request
                );
                 this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.showFailedMessage(
                  "Connection failed",
                  "A network error occurred, please try again."
                );
              }
              this.loadingError = true;
              this.showFailedMessage(
                "Unable to load Resources",
                "An unexpected error occurred, please try again"
              );
            } finally {
              this.loading = false;
            }
          } else if (this.resourceRuleForm.module_cohort == "Cohort") {
            this.dialogVisible2 = true;
            let courseIds = {
              course_ids: this.resourceRuleForm.programmes,
              resource_id: this.resourceId,
            };
            try {
              this.loading = true;
              this.loadingError = false;

              let request = await this.$http.post(
                `course-intake-batches/fetch-many-from-courses`,
                courseIds
              );

              if (
                request.data.success &&
                request.data.message == "COURSE_INTAKE_BATCHES_FETCHED"
              ) {

                this.course_intake_batches = request.data.course_intake_batches;

                      
                let checkedProgrammes = this.course_intake_batches.filter(
                  (resource) => resource.has_supplied_resource == true
                );

                this.cohortRuleForm.intakes = checkedProgrammes.map((item) => {
                  return `${item.course_intake_batch_id}`;
                });
                
              } else {
                 this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.showFailedMessage(
                  "Connection failed",
                  "A network error occurred, please try again."
                );
              }
              this.loadingError = true;
              this.showFailedMessage(
                "Unable to load Resources",
                "An unexpected error occurred, please try again"
              );
            } finally {
              this.loading = false;
            }
          }
        } else {
          return false;
        }
      });
    },

    async continueToCohortResourceUpload() {
      let cohortData = {
        course_intake_batch_ids: this.cohortRuleForm.intakes,
      };
      try {
        this.loading = true;
        this.loadingError = false;

        let request = await this.$http.post(
          `course-intake-batches/fetch-many-including-course-unitd-by-ids`,
          cohortData
        );

        if (
          request.data.success &&
          request.data.message == "COURSE_INTAKE_BATCHES_FETCHED"
        ) {
          let selectedCohorts = request.data.course_intake_batches;
          // this.$router.push({ path: `upload-resources-for-multiple-modules` });

          this.$router.push({
            name: "UpdateResourcesForMultipleCohorts",
            params: {
              resourceId: this.resourceId,
              selectedCohorts: selectedCohorts,
            },
          });
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
           this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load Resources",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async getAllProgrammes() {
      try {
        this.loading = true;
        this.loadingError = false;

        let request = await this.$http.post(`courses/fetch-all-course-names`);

        if (
          request.data.success &&
          request.data.message == "COURSES_NAMES_FETCHED_SUCCESSFULLY"
        ) {
          this.programmes = request.data.courses;
        } else {
           this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load Resources",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    clearInputs() {
      this.selectedResourceType = "";
      this.title = " ";
    },

    doResourceTypeValidation(request, fileType) {
      let result = true;
      if (
        ["notes", "course-works", "past-papers"].includes(
          request.body.resource_type.toLowerCase().trim()
        )
      ) {
        if (
          ![
            "DOC",
            "DOCX",
            "XLS",
            "XLSX",
            "PPT",
            "PPTX",
            "ODT",
            "ODS",
            "ODP",
            "PDF",
            // "TXT",
          ].includes(fileType.toUpperCase())
        ) {
          result = false;
        }
      } else if (request.body.resource_type.trim() === "video") {
        if (
          !["mp4", "ts", "webmv", "avi", "mpeg", "flv", "av"].includes(
            request.body.resource_type.trim().toLowerCase()
          )
        ) {
          result = false;
        }
      }
      return result;
    },
  },
};
</script>

<style>
.input-error {
  border: 1px solid red !important;
  box-shadow: none !important;
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /*  animation-iteration-count:  infinite;  */
}
.not-allowed {
  cursor: not-allowed !important;
  pointer-events: all !important;
}
select.browser-default {
  display: block !important;
  height: 30px !important;
  width: 105%;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  cursor: pointer;
}

.upload-multiple-resource .el-form-item__content {
  margin: 0px !important;
}
.upload-multiple-resource .el-dialog__title {
  font-weight: 500;
}

.upload-multiple-resource .el-select .el-input__inner {
  cursor: pointer;
  width: 100% !important;
  /* padding-left: 10px !important; */
}

.el-date-editor--date .el-input__inner,
.el-range-editor .el-input__inner {
  cursor: pointer;
  width: 100% !important;
  /* padding-left: 45px !important; */
}
.upload-multiple-resource .el-date-editor .el-range-separator {
  display: contents;
  font-size: 0.8em;
  width: 20px !important;
  white-space: nowrap;
}

.upload-multiple-resource
  .el-date-editor.el-range-editor.el-input__inner.el-date-editor--timerange {
  height: 45px !important;
  width: 100%;
}
</style>