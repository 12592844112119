<template>
  <div>
    <!-- <el-button
      :class="[$store.getters.canStaffCrud?'show_list':'hide_list']"
      style="width: 150px; margin-left: 10px"
      class="z-depth-0"
      @click="showAMultipleStaffModal = true"
    >Import File</el-button> -->
    <div>
      <el-dialog
        title="Upload Staff Excel file"
        :visible="isVisible"
        :destroy-on-close="true"
        :before-close="handleClose"
      >
        <div v-loading="submiting">
          <div class="row">
            <div class="col-md-12 text-center mb-5">
              <el-upload
                class="upload-demo"
                drag
                :action="''"
                :file-list="staffFiles"
                :on-remove="handleFileRemove"
                :on-change="handleFilePreview"
                :auto-upload="false"
                :multiple="false"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  Drop file here or <em>click to upload</em>
                </div>
              </el-upload>
              <!-- <div class="form-group">
                <input
                  type="file"
                  id="file"
                  ref="file"
                  name="file"
                  class="form-control"
                >
              </div> -->
            </div>
          </div>
          <div
            class="form-group"
            style="padding-top:10px; display: flex; justify-content:flex-end; "
          >
            <el-button
              style="width: 150px; background-color:  var(--el-app-primary); color:white;font-size: .92em"
              class="z-depth-0"
              :disabled="!selectedFile"
              @click="uploadStaff"
            >
              Upload Staff
            </el-button>
            <!-- <div
              class="text-center"
              @click="downloadFile"
              style="font-weight: 600; cursor: pointer;"
            >
              <span>Download sample <i class="fas fa-cloud-download-alt"></i></span>
            </div> -->
            <el-button
              style="width: 150px; margin-left: 10px; font-size: .92em"
              class="z-depth-0"
              @click="downloadFile"
            >Download Sample</el-button>
          </div>

          <div
            class="text-center"
            style="display: flex; align-items: center; justify-content: space-between;"
          >
            <div class="text-center">
              <small></small>
            </div>

          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submiting: false,
      // showAMultipleStaffModal: false,
      staffFiles: [],
      selectedFile: null,
    };
  },

  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleFilePreview(file) {
      this.staffFiles = [file];
      this.selectedFile = file;
    },
    handleFileRemove() {
      this.selectedFile = null;
    },
    handleClose(done) {
      this.$emit("close-dialog");
      done();
    },
    async uploadStaff() {
      if (this.selectedFile == null) return;
      const formData = new FormData();

      //Ensure only EXCEL Files are submitted
      let file_name = this.selectedFile.name;
      let last_dot_index = file_name.lastIndexOf(".");
      let fileType = file_name.substring(last_dot_index + 1).toLowerCase();

      if (fileType != "xls" && fileType != "xlsx")
        return this.showWarningMessage(
          "Unaccepted File Type",
          "Only Excel Files are allowed, Please check the file and try again"
        );

      formData.append("staffFile", this.selectedFile.raw);
      this.submiting = true;

      try {
        let request = await this.$http.post(`staff/upload-by-file`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (
          request.data.success &&
          request.data.message == "Staff Members uploaded successfully."
        ) {
          this.$emit("on-refresh");
          this.showSuccessMessage(
            "Success",
            "Staff members added successfully"
          );

          // this.showAMultipleStaffModal = false;
          this.$emit("close-dialog");
        } else if (request.data.success == false) {
          this.showWarningMessage("Upload Failed", request.data.message);
        } else {
          this.$rollbar.warning("ADMIN FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED RESPONSE";
        }
      } catch (error) {
        // console.log(error);
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        return this.showFailedMessage(
          "Unexpected Error",
          "Unable to upload staff excel file, Please try again later"
        );
      } finally {
        this.staffFiles = [];
        this.selectedFile = null;
        this.submiting = false;
      }
    },

    async downloadFile() {
      try {
        this.submiting = true;
        const response = await this.$http.get(`staff/sample-file`, {
          responseType: "blob",
        });
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Staff-sample-${new Date().toLocaleDateString()}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        this.showFailedMessage(
          "Download Failed",
          "unable to Download Sample File now. Please try again later"
        );
      } finally {
        this.submiting = false;
      }
    },
  },
};
</script>

<style>
</style>