<template>
  <div class="generate-general-lecture-report">
    <div style="display: flex; align-items: center;">
      <el-dropdown>
        <el-button type="primary"
          style="background-color: var(--el-app-primary) !important; color: white; width: auto; height: 40px">
          Generate Report <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu trigger="click"
          class="generate-general-lecture-report-select"
          slot="dropdown">
          <el-dropdown-item v-for="option in reportOptions"
            :key="option"
            @click.native="onGenerateReportMethod(option)"
            style="display: flex; align-items:center; justify-content: space-between;width: 170px">
            {{ option }}

          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div>
      <!-- DAILY REPORTS -->
      <el-dialog title="Generate Daily Report"
        width="500px"
        :visible.sync="showDailyReportModel"
        :destroy-on-close="true">
        <div v-loading="submiting">
          <div class="row">

            <div class="col-6">
              <div class="block">
                <div class="demonstration pb-2">Select Day </div>
                <el-date-picker v-model="reportDay"
                  :format="'dd MMMM yyyy'"
                  :value-format="'yyyy-MM-dd'"
                  type="date"
                  placeholder="Pick a day">
                </el-date-picker>
              </div>
            </div>
            <div class="col-6 align-items-end"
              style="display:flex">
              <el-button style="background-color: var(--el-app-primary); color: white; width: auto; height: 2.6rem"
                class="z-depth-0 addbtn"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                :disabled="!reportDay"
                @click="onGenerateReport">Generate Report</el-button>
            </div>
          </div>

        </div>
      </el-dialog>

      <!-- WEEKLY REPORTS -->
      <el-dialog title="Generate Weekly Report"
        width="500px"
        :visible.sync="showWeeklyReportModel"
        :destroy-on-close="true">
        <div v-loading="submiting">
          <div class="row">

            <div class="col-6">
              <div class="block">
                <div class="demonstration pb-2">Select Week </div>
                <el-date-picker v-model="week"
                  type="week"
                  format="Week WW"
                  placeholder="Pick a week">
                </el-date-picker>
              </div>
            </div>
            <div class="col-6 align-items-end"
              style="display:flex">
              <el-button style="background-color: var(--el-app-primary); color: white; width: auto; height: 2.6rem"
                class="z-depth-0 addbtn"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                :disabled="!week"
                @click="onGenerateReport">Generate Report</el-button>
            </div>
          </div>

        </div>
      </el-dialog>

      <!-- MONTHLY REPORTS -->
      <el-dialog title="Generate Monthly Report"
        width="500px"
        :visible.sync="showMonthlyReportModel"
        :destroy-on-close="true">
        <div v-loading="submiting">
          <div class="row">

            <div class="col-6">
              <div class="block">
                <div class="demonstration pb-2">Select Month </div>
                <el-date-picker v-model="monthAndYear"
                  :format="'MMMM yyyy'"
                  :value-format="'yyyy-MM-dd'"
                  type="month"
                  placeholder="Pick a month">
                </el-date-picker>
              </div>
            </div>
            <div class="col-6 align-items-end"
              style="display:flex">
              <el-button style="background-color: var(--el-app-primary); color: white; width: auto; height: 2.6rem"
                class="z-depth-0 addbtn"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                :disabled="!monthAndYear"
                @click="onGenerateReport">Generate Report</el-button>
            </div>
          </div>

        </div>
      </el-dialog>
    </div>
    <ChooseLiveLectureStatsTimePeriodDialog 
      :show-dialog="showChooseLiveLectureStatsTimePeriodDialog"
      :is-student-attendance-stats="isStudentAttendanceStats"
      @close-dialog="showChooseLiveLectureStatsTimePeriodDialog = false"
       />
  </div>
</template>

<script>
import { format, startOfWeek } from "date-fns";
import ChooseLiveLectureStatsTimePeriodDialog from "./choose-live-lecture-stats-time-period-for-export-dialog.vue";

export default {
  data() {
    return {
      submiting: false,
      showMonthlyReportModel: false,
      showDailyReportModel: false,
      showWeeklyReportModel: false,
      monthAndYear: null,
      reportDay: null,
      reportType: null,
      week: null,
      reportOptions: ["Daily Report", "Weekly Report", "Monthly Report", "Students Attendance Stats", "Staff Attendance Stats"],
      showChooseLiveLectureStatsTimePeriodDialog: false,
      isStudentAttendanceStats: false,
    };
  },

  components: {
    ChooseLiveLectureStatsTimePeriodDialog
  },

  methods: {
    onGenerateReportMethod(reportType) {
      this.reportType = reportType;
      if (reportType == "Daily Report") {
        this.showDailyReportModel = true;
        this.showMonthlyReportModel = false;
        this.showWeeklyReportModel = false;
      } else if (reportType == "Weekly Report") {
        this.showWeeklyReportModel = true;
        this.showMonthlyReportModel = false;
        this.showDailyReportModel = false;
      } else if (reportType == "Monthly Report") {
        this.showMonthlyReportModel = true;
        this.showDailyReportModel = false;
        this.showWeeklyReportModel = false;
      } else if (reportType == "Students Attendance Stats") {
        this.showChooseLiveLectureStatsTimePeriodDialog = true;
        this.isStudentAttendanceStats = true;
        this.showMonthlyReportModel = false;
        this.showDailyReportModel = false;
        this.showWeeklyReportModel = false;
      } else if (reportType == "Staff Attendance Stats") {
        this.showChooseLiveLectureStatsTimePeriodDialog = true;
        this.isStudentAttendanceStats = false;
        this.showMonthlyReportModel = false;
        this.showDailyReportModel = false;
        this.showWeeklyReportModel = false;
      }
    },

    async onGenerateReport() {
      try {
        this.submiting = true;
        let request = await this.$http.post(
          `/live-lecture/generate-report-for-all`,
          {
            reportType: this.reportType,
            monthAndYear: this.monthAndYear,
            reportDay: this.reportDay,
            week: this.week
              ? format(startOfWeek(this.week), "yyyy-MM-dd")
              : null,
          }
        );

        if (
          request.data.success &&
          request.data.message == "LECTURES_REPORT_GENERATED"
        ) {
          this.showSuccessMessage(
            "Report Generated",
            "Report Generated Successfully"
          );

          const link = document.createElement("a");
          link.href = request.data.link;
          // link.download = "Question Paper";
          link.click();
        } else if (request.data.message == "NO_LIVE_LECTURES_FOUND") {
          return this.showWarningMessage(
            "No Records",
            "There are no Live Lecture Records to generate Reports for."
          );
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response",
            request.data,
            request
          );

          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        console.log(error);
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        } else {
          this.showFailedMessage(
            "Unexpected error occurred",
            "Unable to Generate Report now. Please try again later."
          );
        }
      } finally {
        this.submiting = false;
      }
    },
  },
};
</script>

<style>
.generate-general-lecture-report-select .el-dropdown-menu__item {
  width: 220px !important;
}

.generate-general-lecture-report .el-dropdown-menu {
  width: 230px !important;
}
</style>