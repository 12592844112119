<template>
  <div class="container-fluid">
    <div class="row w-100">

      <div v-if="resources.length === 0" class="col-12" style="margin-top: 80px">
        <img src="../../assets/img/no-content.png" alt="" style="height: 100px"/>
        <div class="pt-3" style="font-weight: 500; font-size: 0.9em; color: rgba(0, 0, 0, 0.45)">
          There are no {{ title }} to display
        </div>
      </div>

      <div class="text-left" style="width: 100%;">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item v-for="item in resources" :key="item.weekLabel" :title="item.subLabel ? `${item.weekLabel} -> (${item.subLabel})` : `${item.weekLabel}` " :name="item.weekLabel"> 
            <div class="row w-100">
              <div 
                v-for="resource in item.resources" 
                :key="resource.resource_id"
                class="col-md-4"
                @click="$emit('on-select-resource', resource)"
                style="padding-bottom: 30px">
                <div class="content-card text-left"
                  v-loading="isLoadingID == resource.resource_id">
                  <div class="col-12">
                    <div style="font-size: 1em; margin-right: 45px; font-weight: 600"
                      class="text-left text-truncate">
                      {{ resource.title }}
                    </div>
                    <div v-if="resource.resource_type == 'video'"
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          background-color: var(--vu-red);
                          color: white;
                          position: absolute;
                          right: 0px;
                          top: 0px;
                          height: 40px;
                          width: 40px;
                          object-fit: cover;
                          border-radius: 100%;"
                      >
                        <span class="fas fa-video" style="font-size: 20px"></span>
                    </div>
                    <div v-else style="position: absolute; right: 15px; top: 0px; height: 40px; width: 40px;">
                      <img :src="require(`@/assets/img/${documentImage(resource.path_url)}`)" />
                    </div>
                  </div>
                  <div class="col-12 pt-3" style="font-size: 0.8em">
                    Uploaded on:
                    <!-- <strong style="font-weight: 500">
                      {{ formattedDate(resource.created_at) }}</strong
                    > -->
                  </div>
                  <div class="col-12 pt-2 text-truncate" style="font-size: 0.8em">
                    Uploaded by:
                    <strong style="font-weight: 500"
                      >{{ resource.creator.first_name }}
                      {{ resource.creator.last_name }}</strong
                    >
                  </div>

                  
                  <div v-if="resource.is_available == true" class="col-12 pt-3" style="display: flex; justify-content: space-between; font-size: 0.8em">
                    <div>
                      File Size:
                      <strong style="font-weight: 500">{{ toFileSize(resource.file_size) }} </strong>
                    </div>
                    <div><small style="color: green;">Available</small></div>
                  </div>

                  <div v-else-if="resource.is_available == false" class="col-12 pt-2" style="display: flex; justify-content: space-between; font-size: 0.8em">
                    
                    <div v-if="resource.is_available == false">
                      <small>
                      Priemiering on:
                      <strong style="font-weight: 500" v-if="resource.maturity_date">
                      {{ formattedDate(resource.maturity_date) }}</strong>
                      </small><br/>
                    </div>
                    <div style="font-size: 0.8em">
                      <span> 
                        <i class="fas fa-lock"
                          style="font-size: 20px; color: var(--vu-red); position: absolute; right: 10px; bottom: 10px"></i>
                      </span>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      
    </div>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
// import fileSize from "filesize";
const fileSize = require("filesize");

export default {
  data() {
    return {
      isLoadingID: "",
      activeName: 'General',
    };
  },
  computed: {
    documentImage() {
      return (filePath) => {
        let last_dot_index = filePath.lastIndexOf(".");
        const fileType = filePath.substr(last_dot_index + 1)?.toLowerCase();

        if (fileType == "pdf" || fileType == "wps-office.pdf") return "pdf.png";
        if (fileType == "xls" || fileType == "xlsx") return "excel.png";
        if (fileType == "ppt" || fileType == "pptx") return "ppt.png";
        if (fileType == "zip" || fileType == "rar") return "zip.png";
        return "word.png";
      };
    },
    toFileSize() {
      return (size) => {
        return fileSize(size);
      };
    },
  },
  props: {
    resources: {
      required: true,
      type: Array,
    },
    title: {
      required: true,
      type: String,
    },
  },

  methods: {
    formattedDate(dateIso) {
      return format(parseISO(dateIso), "do MMM yyyy");
    },
  },
};
</script>

<style scoped>
.content-card {
  border: 1px solid #bebebea1;
  background-color: #faffff63;
  border-radius: 8px;
  padding: 10px 10px;
  cursor: pointer;
  height: 145px;
}

.el-icon-delete {
  cursor: pointer;
  transition: color 1s;
}

.el-icon-delete:hover {
  color: rgba(253, 0, 0, 0.692) !important;
  transition: color 1s;
}
</style>

<style>
  .el-collapse-item__header {
    background-color: #F6F6F6;
    color: #303133;
    cursor: pointer;
    border-bottom: 1px solid rgb(161, 161, 161);
    font-size: 1.0em;
    font-weight: 500;
  }

  .el-collapse-item__content {
    background-color: #F6F6F6;
    padding-bottom: 25px;
    font-size: 13px;
    color: #303133;
    line-height: 1.769230769230769;
}
</style>